<template>
  <v-dialog v-model="deleteDialog" max-width="650px">
    <template v-slot:activator="{ on }">
      <v-btn rounded fab dark color="red" text x-small v-on="on">
        <v-icon small> mdi-delete </v-icon>
      </v-btn>
    </template>
    <v-card class="rounded-xl pa-4">
      <v-card-title></v-card-title>
      <v-card-text class="pt-1">
        <v-row class="justify-center mb-1">
          <h4>
            Êtes-vous sûr de vouloir supprimer l'utilisateur ?
          </h4>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-row>
          <v-col cols="12">
            <v-btn
              class="white--text"
              color="new_orange"
              block
              @click="deleteItem(item)"
              >Supprimer</v-btn
            >
          </v-col>
          <v-col cols="12" class="mt-n2">
            <v-btn block @click="closeDeleteDialog"> Annuler </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      deleteDialog: false
    };
  },
  methods: {
    closeDeleteDialog() {
      this.deleteDialog = false;
    }
  }
};
</script>
