<template>
  <v-container>
    <h3 class="mb-3">
      {{ title }}
    </h3>
    <v-container>
      <v-row>
        <v-col class="outlined columnTitle">
          {{ nameFirstColumn }}
        </v-col>
        <v-col class="outlined columnTitle">
          {{ nameSecondColumn }}
        </v-col>
      </v-row>
      <v-row v-for="key in keys" :key="key">
        <v-col class="outlined">
          {{ key }}
        </v-col>
        <v-col class="outlined">
          {{ datas[key] }}
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: ""
    },
    nameFirstColumn: {
      type: String,
      default: ""
    },
    nameSecondColumn: {
      type: String,
      default: ""
    },
    datas: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  computed: {
    keys() {
      return Object.keys(this.datas);
    }
  }
};
</script>

<style scoped>
.outlined {
  border: solid 1px #ccc;
}

.columnTitle {
  font-weight: bold;
}
</style>
