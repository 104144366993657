<template>
  <v-container>
    <!-- Compteur -->
    <v-row align="center" class="pr-3">
      <h4 class="black_text--text ml-3">
        {{ KPITimeSelected }}
      </h4>
      <v-spacer></v-spacer>
      <filter-dates
        :start-date="startDate"
        :end-date="endDate"
        @filtered="filterDates"
      ></filter-dates>
    </v-row>
    <v-row class="py-4 mb-6">
      <v-col
        v-for="counter in kpi_counters_row_1"
        :key="counter.queryName"
        class="xs"
        cols="12"
        sm="6"
        md="3"
      >
        <hmd-counter
          class="mb-0"
          :label="counter.label"
          :value="counter.value"
          :value-type="counter.valueType"
          :query-name="counter.queryName"
          :last-row="counter.lastRow"
          :is-blue="counter.isBlue"
          :rate="counter.rate"
          :date-filter-start="startDate"
          :date-filter-end="endDate"
          sm="6"
          md="3"
        />
      </v-col>
    </v-row>
    <v-row class="py-2">
      <v-col class="xs" cols="12" sm="12" md="3">
        <hmd-table-chart
          class="actualiser donut-chart mb-6"
          title="Destination des CPEs reçus"
          subtitle="Indicateurs du mois en cours à J-1"
          format-type="numbers"
          query-name="repartition"
          :parameters="queryParameters"
          x-axis="etat_sortie"
          y-axis="Total"
          chart-type="donut"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="9">
        <hmd-table-chart
          class="actualiser"
          format-type="currency"
          title="Total des coûts pour la maintenance"
          subtitle="Indicateurs de l'année passée à J-1"
          query-name="depense"
          :parameters="queryParameters"
          x-axis="date"
          y-axis="total"
          chart-type="bar"
          bar-color="yellow"
          :step-size="10000"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          format-type="currency"
          title="Total des recettes rachat de CPEs"
          query-name="total_recettes_rachat_cpe"
          :parameters="queryParameters"
          x-axis="date"
          y-axis="montant"
          :ignored-columns="['primary_key']"
          chart-type="bar"
          bar-color="blue"
          :step-size="5000"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          title="Coût RSE"
          subtitle="Données mockées"
          format-type="numbers"
          :dataset="coutRseDataset"
          x-axis="date"
          y-axis="total"
          chart-type="area"
          :step-size="25"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Counter from "@/components/bnc/dashboard/Counter";
import TableChart from "@/components/bnc/dashboard/TableChart";
import FilterDates from "@/components/hmd/FilterDates.vue";
import Constant from "@/constant";

export default {
  components: {
    "hmd-counter": Counter,
    "hmd-table-chart": TableChart,
    "filter-dates": FilterDates
  },
  data() {
    return {
      period: "12 Month",
      kpi_counters_row_1: [
        {
          label: "Coût de transport",
          value: "Coût de transport",
          valueType: "currency",
          queryName: "4",
          lastRow: false,
          isBlue: false
        },
        {
          label: "Coût de la prestation",
          value: "Coût de la prestation",
          valueType: "currency",
          queryName: "2",
          lastRow: false,
          isBlue: false
        },
        {
          label: "Nombre de matériels reçus",
          value: "Nombre de matériels reçus",
          valueType: "",
          queryName: "3",
          lastRow: false,
          isBlue: false
        },
        {
          label: "Montant des CPEs rachetés",
          value: "Montant des CPEs rachetés",
          valueType: "currency",
          queryName: "1",
          lastRow: false,
          isBlue: false
        }
      ],
      startDate: new Date(),
      endDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      timeSelected: "",
      coutRseDataset: Constant.datasetCoutRse.id
    };
  },
  computed: {
    queryParameters() {
      const parameters = [];
      parameters.push({
        name: "interval",
        type: "Text",
        value: this.period
      });
      return parameters;
    },
    KPITimeSelected() {
      switch (this.timeSelected) {
        case "1_month":
          return "Indicateurs du mois en cours à J-1";
        case "3_month":
          return "Indicateurs des 3 derniers mois à J-1";
        case "6_month":
          return "Indicateurs des 6 derniers mois à J-1";
        case "12_month":
          return "Indicateurs de l'année passée à J-1";
        case "custom":
          return (
            "Indicateurs du " +
            this.startDate.toLocaleDateString("fr-FR") +
            " au " +
            this.endDate.toLocaleDateString("fr-FR")
          );
        default:
          return "Indicateurs du mois en cours à J-1";
      }
    }
  },
  methods: {
    filterDates(eventObject) {
      this.startDate = eventObject.startDate;
      this.endDate = eventObject.endDate;
      this.timeSelected = eventObject.timeSelected;
    }
  }
};
</script>

<style scoped>
.donut-chart {
  max-height: 347px !important;
}
</style>
