<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn v-if="compact" class="primary--text" icon v-on="on">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-btn v-else small plain class="filter_btn ml-6" v-on="on">
        Uploader un fichier
        <v-icon class="primary--text">mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-col cols="10">
          <span class="text-h6 primary--text mb-0">
            Ajouter un nouveau fichier
          </span>
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn icon class="primary--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-btn
          color="grey lighten-2"
          class="new_blue--text rounded-xl"
          block
          min-height="280px"
          :loading="isSelecting"
          @click="handleFileImport"
        >
          <v-icon large>mdi-plus</v-icon>
          <br />
          <span v-if="selectedFile">
            Fichier sélectionné : {{ selectedFile.name }}
          </span>
          <span v-else>Selectionner un fichier</span>
        </v-btn>
        <input
          ref="uploader"
          class="d-none"
          type="file"
          @change="onFileChanged"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn
          block
          large
          color="new_orange"
          class="white--text mb-6 rounded-lg"
          :disabled="!selectedFile"
          @click="validate"
        >
          Ajouter
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    compact: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      dialog: false,
      isSelecting: false,
      selectedFile: null
    };
  },
  watch: {
    dialog(newVal) {
      if (newVal) {
        this.selectedFile = null;
        this.isSelecting = false;
      }
    }
  },
  methods: {
    close() {
      this.dialog = false;
    },
    validate() {
      this.$emit("fileSelected", this.selectedFile);
      this.close();
    },
    handleFileImport() {
      this.isSelecting = true;

      window.addEventListener(
        "focus",
        () => {
          this.isSelecting = false;
        },
        { once: true }
      );

      this.$refs.uploader.click();
    },
    onFileChanged(e) {
      this.selectedFile = e.target.files[0];
    }
  }
};
</script>
