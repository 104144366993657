<template>
  <div
    class="hmd_snackbar_queue_container"
    @click="
      e => {
        e.preventDefault();
      }
    "
  >
    <v-snackbar
      v-for="(s, index) in snacks"
      id="hmd_custom_snack"
      :key="index"
      v-model="s.isVisible"
      right
      dismissible
      :timeout="s.timeout"
      :color="s.color"
    >
      <span style="white-space:pre-wrap;">{{ s.text }}</span>
      <v-btn color="white" text @click.native="s.isVisible = false">
        Close
      </v-btn>
    </v-snackbar>
  </div>
</template>
<style>
#hmd_custom_snack {
  display: block;
  position: relative;
  margin-bottom: 10px;
  left: -10px;
}
.hmd_snackbar_queue_container {
  position: fixed;
  right: 10px;
  top: 20px;
  z-index: 9999;
}
</style>
<script>
import moment from "moment";
export default {
  name: "Notify",
  data: () => ({}),
  computed: {
    snacks() {
      const errorLine = this.$store.state.errorLine;
      return this.lo.filter(errorLine, ml => {
        const m = moment(ml.createdAt).add(ml.timeout, "milliseconds");
        if (moment().isBefore(m)) {
          ml.isVisible = true;
          return true;
        } else {
          return false;
        }
      });
    }
  }
};
</script>
