<template>
  <v-app>
    <v-main>
      <v-container class="pa-0 fill-height" fluid>
        <v-row
          class="fill-height pa-0 ma-0 bg-cover"
          align="center"
          justify="center"
        >
          <v-col cols="12" md="8" sm="12" lg="6" xl="4">
            <v-card class="pa-5 rounded-xl">
              <v-row class="pa-7 fill-height" align="center" justify="center">
                <v-col class="pa-3" cols="12">
                  <v-row justify="center">
                    <img
                      src="@/assets/logo-bouygues-telecom-entreprises.png"
                      class="logo-bte"
                    />
                  </v-row>
                  <v-row justify="center">
                    <span class="font-weight-bold text-body-1">Bienvenue</span>
                  </v-row>
                  <v-row justify="center">
                    <span class="black--text font-weight-light text-subtitle-2"
                      >Connectez-vous à la plateforme de suivi des flux
                      matériels
                    </span>
                  </v-row>
                  <v-form>
                    <v-text-field
                      v-model="email"
                      name="email"
                      placeholder="Adresse email"
                      :error-messages="errors.email"
                      autofocus
                      class="input-group--focused mt-5"
                      @blur="$v.email.$touch()"
                      v-on:keyup.enter="submit"
                    />
                    <password-field
                      v-model="password"
                      v-on:keyup.enter="submit"
                      name="password"
                    />
                    <v-btn
                      dark
                      large
                      block
                      :disabled="false"
                      color="accent white--text"
                      class="my-3 redbouygue"
                      @click="submit"
                    >
                      Connexion
                    </v-btn>
                  </v-form>
                  <reset-password v-model="email" />
                  <v-row justify="center">
                    <span
                      class="text-center text-caption mt-4 font-weight-light subtitle--text"
                    >
                      Si vous ne parvenez pas à vous connecter, veuillez
                      contacter le reponsable :
                      <a
                        class="new_blue--text"
                        href="mailto:GLESNE@bouyguestelecom.fr"
                      >
                        GLESNE@bouyguestelecom.fr
                      </a>
                    </span>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
          <error-handler />
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>
<script>
import ResetPassword from "@/components/utils/ResetPassword";
// import ResendActivationEmail from "@/components/utils/ResendActivationEmail";
import ErrorHandler from "@/components/utils/ErrorHandler";
import { validationMixin } from "vuelidate";
import { required, email } from "vuelidate/lib/validators";
import { login } from "@/utils/auth";
import Constant from "@/constant";
import PasswordField from "@/components/base/PasswordField";

const Config = require("@/utils/theme-config");
export default {
  components: {
    PasswordField,
    ErrorHandler,
    // ResendActivationEmail,
    ResetPassword
  },
  mixins: [validationMixin],
  validations: {
    password: { required },
    email: {
      email,
      required
    }
  },
  data: function() {
    return {
      app: {
        name: Config.app.name,
        url: Config.app.url
      },
      email: "",
      password: "",
      eyeIcon: "mdi-eye",
      passwordType: "password"
    };
  },
  computed: {
    errors() {
      const errors = {
        password: [],
        email: []
      };

      if (this.$v.password.$dirty) {
        !this.$v.password.required &&
          errors.password.push("Le mot de passe est requis.");
      }
      if (this.$v.email.$dirty) {
        if (!this.$v.email.email) {
          errors.email.push("L'adresse email doit être valide.");
        }
        if (!this.$v.email.required) {
          errors.email.push("L'adresse email est requis.");
        }
      }
      return errors;
    }
  },
  mounted: function() {
    this.clear();
  },
  methods: {
    submit() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        this.call_login();
      }
    },
    call_login() {
      var credentials = {
        email: this.email,
        password: this.password
      };

      login(this, credentials, this.$route.query.redirect).then(r => {
        const groups = r.groups.map(g => g.name);
        const group = groups.length > 0 ? groups[0] : null;
        if (Constant.DEBUG) {
          console.log(r);
          console.log(group);
        }
        switch (group) {
          case Constant.lecteur_bouygue_groupname:
            window.location.replace("/numero_serie");
            break;
          case Constant.plurium_groupname:
            window.location.replace("/statistiques");
            break;
          case Constant.super_admin_bouygue_groupname:
          case Constant.admin_bouygue_groupname:
            window.location.replace("/overview");
            break;
          default:
            this.$store.commit("error", {
              msg:
                "Vous n'avez pas les autorisations pour accéder à cette application.",
              color: "error"
            });
            break;
        }
      });
    },
    clear() {
      this.$v.$reset();
      this.email = "";
      this.password = "";
      this.checkbox = null;
    },
    togglePassword() {
      this.passwordType =
        this.passwordType === "password" ? "text" : "password";
      this.eyeIcon = this.eyeIcon === "mdi-eye" ? "mdi-eye-off" : "mdi-eye";
    }
  }
};
</script>
<style scoped>
.bg-cover {
  background-size: cover;
  background-position: 50% 50%;
  background-image: url(../assets/login.jpg);
  background-color: rgba(0, 85, 164, 0.45);
  background-blend-mode: overlay;
}

.logo-bte {
  height: "150px";
  margin-left: -30px;
  max-width: 100%;
  scale: 0.55;
}

.powered_by {
  position: absolute;
  bottom: 30px;
  left: 30px;
}

.lightheader {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
  color: white;
  font-size: 30px;
  margin-left: 10%;
  margin-top: 10%;
}
</style>
