<template>
  <v-btn
    class="filter_btn black--text my-3 mx-6 white--text"
    small
    elevation="0"
    @click="exportTable"
  >
    Exporter
    <v-icon class="primary--text">mdi-tray-arrow-up</v-icon>
  </v-btn>
</template>

<script>
import DatasetService from "@/services/DatasetService.js";

export default {
  props: {
    filename: {
      type: String,
      required: true
    },
    datasetId: {
      type: String,
      required: true
    }
  },
  methods: {
    exportTable() {
      DatasetService.exportCsv(this, this.datasetId, this.filename);
    }
  }
};
</script>
