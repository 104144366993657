<template>
  <v-card
    :class="{ new_blue: isBlue }"
    :loading="loading"
    width="100%"
    height="auto"
    max-height="132"
    class="text-center pa-7"
    rounded="xl"
    elevation="0"
  >
    <div
      :class="{ 'black--text': !isBlue, 'white--text': isBlue }"
      class="value text-h4 font-weight-bold"
    >
      <template v-if="valueType == 'currency'">
        {{ val | currency }} €
      </template>
      <template v-else>
        {{ val === "-" ? "0" : val }}
      </template>
    </div>
    <div
      :class="{ 'new_blue--text': !isBlue, 'white--text': isBlue }"
      class="text-subtitle-1 mt-2 display-inline text-break"
    >
      {{ label }}
    </div>
  </v-card>
</template>

<script>
import Numeral from "@/plugins/numeral";
import QueryService from "@/services/QueryService.js";
export default {
  filters: {
    currency: function(value) {
      return Numeral(value).format();
    }
  },
  props: {
    label: {
      type: String,
      default: null,
      required: true
    },
    queryName: {
      type: String,
      default: null,
      required: true
    },
    valueType: {
      type: String,
      default: null
    },
    value: {
      type: String,
      default: null,
      required: true
    },
    lastRow: {
      type: Boolean,
      default: false,
      required: false
    },
    isBlue: {
      type: Boolean,
      default: false,
      required: false
    },
    dateFilterStart: {
      type: Date,
      required: true
    },
    dateFilterEnd: {
      type: Date,
      required: true
    }
  },
  data() {
    return {
      loading: false,
      val: "-",
      parameters: []
    };
  },
  watch: {
    dateFilterStart: function() {
      this.get();
    },
    dateFilterEnd: function() {
      this.get();
    }
  },
  mounted() {
    this.get();
  },
  methods: {
    get() {
      const $ = this;
      $.loading = true;

      this.parameters = [];
      this.parameters.push({
        name: "filter_date",
        type: "text",
        value:
          this.dateFilterEnd.toLocaleDateString("fr-CA") + // Don't change format for both these 2 dates
          " " +
          this.dateFilterStart.toLocaleDateString("fr-CA") // they are NOT displayed, only used during handling
      });

      if ($.queryName) {
        QueryService.run($, `kpi_${$.queryName}`, this.parameters).then(r => {
          $.val = r.data.rows[0].text_value;
          $.loading = false;
        });
      }
    }
  }
};
</script>

<style scoped>
.subtitle-1 {
  font-family: "Open Sans", sans-serif;
  color: #004480;
}
</style>
