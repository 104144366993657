import _ from "lodash";
// https://vuex.vuejs.org/en/mutations.html

export default {
  setUser(state, user) {
    localStorage.setItem("user", JSON.stringify(user));
  },
  setGroup(state, group) {
    localStorage.setItem("group", JSON.stringify(group));
  },
  confirm(state, confirmation) {
    confirmation.id = _.uniqueId();
    confirmation.width = confirmation.width || 290;
    confirmation.onConfirm = confirmation.onConfirm || null;
    confirmation.onCancel = confirmation.onCancel || null;
    confirmation.color = confirmation.color || "warning";

    return state.app.confirmLine.push(confirmation);
  },
  push(state, ml) {
    ml.id = _.uniqueId();
    return state.app.messageLine.push({
      id: ml.id,
      text: ml.msg,
      color: ml.color,
      isVisible: true,
      createdAt: new Date().getTime(),
      timeout: 3000
    });
  },
  shift(state) {
    return state.app.messageLine.shift();
  },
  error(state, ml) {
    return state.errorLine.push({
      id: _.uniqueId(),
      text: ml.msg,
      color: ml.color,
      isVisible: true,
      createdAt: new Date().getTime(),
      timeout: 3000
    });
  },
  success(state, msg) {
    return state.errorLine.push({
      id: _.uniqueId(),
      text: msg,
      color: "success",
      isVisible: true,
      createdAt: new Date().getTime(),
      timeout: 3000
    });
  },
  online(state) {
    state.isOnline = true;
  },
  offline(state) {
    state.isOnline = false;
  }
};
