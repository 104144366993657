<template>
  <v-dialog v-model="dialog" scrollable persistent max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn
        color="new_blue"
        dark
        class="mb-2 rounded-lg"
        v-on="on"
        @click="open"
      >
        <v-icon left>
          mdi-plus
        </v-icon>
        Nouveau
      </v-btn>
    </template>
    <v-card class="rounded-xl">
      <v-card-title>
        <v-col cols="5">
          <span class="text-h6 primary--text">{{ formTitle }}</span>
        </v-col>
        <v-col cols="7" class="text-right">
          <v-btn icon class="primary--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text class="pt-1">
        <v-row>
          <v-col cols="12"> </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-text-field
              v-model="user.first_name"
              name="first_name"
              label="Prénom"
              outlined
            ></v-text-field>
            <v-col cols="12" class="ma-0 py-0"></v-col>
            <v-text-field
              v-model="user.last_name"
              name="last_name"
              label="Nom"
              outlined
            ></v-text-field>
          </v-col>
          <v-col v-if="!user.id" cols="12" class="ma-0 py-0">
            <v-text-field
              v-model="user.email"
              :error-messages="errors.email"
              name="email"
              label="Email"
              outlined
              :rules="[$v.user.email.required, $v.user.email.email]"
              @blur="$v.user.email.$touch()"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-select
              v-model="user.group"
              :error-messages="errors.group"
              :items="authorizedGroups"
              outlined
              name="group"
              label="Groupe"
              @blur="$v.user.group.$touch()"
            ></v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="text-center mt-n7">
          <v-btn
            block
            large
            color="new_orange"
            class="white--text"
            :loading="loading_save"
            @click="save"
          >
            {{ user.id ? "Modifier" : "Ajouter" }}
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constant";
import CompanyService from "@/services/CompanyService";
import CompanyGroupService from "@/services/CompanyGroupService";
import { required, email } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import _ from "lodash";

export default {
  mixins: [validationMixin],
  validations: {
    user: {
      email: {
        required,
        email
      },
      first_name: {},
      last_name: {},
      company: { required },
      group: {
        required
      }
    },
    validationGroup: ["user"]
  },
  props: {
    value: {
      type: Object,
      default: () => {
        return {
          id: null,
          email: "",
          first_name: "",
          last_name: "",
          company: Constant.company,
          group: null
        };
      }
    },
    authorizedGroups: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      dialog: false,
      loading_save: false,
      user: this.value,
      group: this.$store.getters.group,
      admins: [...Constant.super_admin_groups()],
      defaultGroupId: Constant.default_group_id
    };
  },
  computed: {
    edit() {
      return !!this.value?.id;
    },
    formTitle() {
      return this.edit ? "Modifier l'utilisateur" : "Nouvel utilisateur";
    },
    errors() {
      const $ = this;
      const errs = {
        email: [],
        group: []
      };

      if ($.$v.user.email.$dirty) {
        !$.$v.user.email.required && errs.email.push("L'email est requis");
        !$.$v.user.email.email && errs.email.push("L'email doit être valide");
      }
      if ($.$v.user.group.$dirty) {
        !$.$v.user.group.required &&
          errs.group.push("L'utilisateur doit faire parti d'un groupe");
      }
      return errs;
    }
  },
  watch: {
    value(newValue, oldValue) {
      if (newValue.id) {
        this.user = newValue;
        this.user.company = this.user.company.toLowerCase();
        this.dialog = true;
      }
    }
  },
  methods: {
    open() {
      this.$v.$reset();
    },
    reset() {
      this.user = {
        id: null,
        email: "",
        first_name: "",
        last_name: "",
        company: Constant.company,
        group: null
      };
    },
    close() {
      this.dialog = false;
      this.reset();
      this.$emit("closed");
    },
    async save() {
      this.loading_save = true;
      this.user.company = Constant.company;
      this.$v.user.$touch();
      if (!this.$v.user.$invalid) {
        const userformated = _.cloneDeep(this.user);
        userformated.groups = [userformated.group];
        if (this.user.id != null) {
          let companyGroups = await CompanyGroupService.list_group_company(
            this
          );
          companyGroups = companyGroups.filter(g =>
            Constant.groups().includes(g.name)
          );
          companyGroups.forEach(g => {
            if (g.members.find(m => m.id === this.user.id)) {
              CompanyGroupService.remove_member_group_company(
                this,
                g.id,
                this.user.id
              );
            }
          });
          CompanyService.User.update(this, userformated, this.user.id)
            .then(r => {
              this.$emit("updated");
              this.close();
            })
            .finally(() => {
              this.loading_save = false;
            });
        } else {
          userformated.username = userformated.email;

          let companyGroups = await CompanyGroupService.list_group_company(
            this
          );
          companyGroups = companyGroups.filter(g =>
            Constant.groups().includes(g.name)
          );

          const group = companyGroups.find(g => g.name === userformated.group)
            .id;

          CompanyService.User.create(this, userformated)
            .then(r => {
              CompanyGroupService.add_member_group_company(
                this,
                group,
                r.pk
              ).then(() => {
                // BTE-Utilisateur is a default group that we don't want, ONLY if the user isn't supposed to be in this group
                // Its ID is 11 in rec
                // TODO : Remove this hard coded value
                if (!userformated.groups.includes("BTE-Utilisateur")) {
                  CompanyGroupService.remove_member_group_company(
                    this,
                    this.defaultGroupId,
                    r.pk
                  );
                }
                this.$emit("created");
                this.close();
              });
            })
            .finally(() => {
              this.loading_save = false;
            });
        }
      } else {
        this.$store.commit("error", "L'utilisateur n'a pas pu être créé");
        this.loading_save = false;
      }
    }
  }
};
</script>

<style lang="scss" scoped></style>
