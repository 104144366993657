/**
 * Define all of your application routes here
 * for more information on routes, see the
 * official documentation https://router.vuejs.org/en/
 */

import Layout from "@/components/layouts/Layout.vue";

import Dashboard from "@/views/Dashboard.vue";
import Indicators from "@/views/Indicators.vue";
import UserProfile from "@/views/UserProfile.vue";
import Signin from "@/views/Signin.vue";
import Signup from "@/views/Signup.vue";
import NewPassword from "@/views/NewPassword";

import { requireAuth } from "@/utils/auth";

import References from "@/views/References.vue";
import NumeroSerie from "@/views/NumeroSerie.vue";
import Alertes from "@/views/Alertes.vue";

import StatPrint from "@/views/StatPrint.vue";
import Users from "@/views/admin/Users";
import Facturation from "@/views/Facturation";
import Stocks from "@/views/Stocks.vue";
import Treatment from "@/views/Treatment";
import Flux from "@/views/Flux";

import Test from "@/views/Test";

const ActiveLayout = Layout;
const UserRoutes = [];
const AdminRoutes = [
  {
    path: "/overview",
    component: ActiveLayout,
    beforeEnter: requireAuth
  },
  {
    path: "/manage",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Manage",
        component: Users
      }
    ]
  }
];

const SharedRoutes = [
  {
    path: "/overview",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Synthèse de l'activité",
        component: Dashboard
      }
    ]
  },
  {
    path: "/stock",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Suivi des stocks",
        component: Stocks
      }
    ]
  },
  {
    path: "/history",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Historique des commandes",
        component: Stocks,
        props: { isHistory: true }
      }
    ]
  },
  {
    path: "/treatment",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Traitement de KOs",
        component: Treatment
      }
    ]
  },
  {
    path: "/references",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Références",
        component: References
      }
    ]
  },
  {
    path: "/numero_serie",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Suivi dossier client",
        component: NumeroSerie
      }
    ]
  },
  {
    path: "/signin",
    name: "Sign in",
    component: Signin
  },
  {
    path: "/signup/:key",
    name: "Sign up",
    component: Signup
  },
  {
    path: "/password_reset/:uid/:key",
    name: "Reset password",
    component: NewPassword
  },
  {
    path: "/profile",
    component: ActiveLayout,
    beforeEnter: requireAuth,
    children: [
      {
        path: "",
        name: "Profil",
        component: UserProfile
      }
    ]
  },
  {
    path: "",
    beforeEnter: requireAuth,
    redirect: "/overview"
  },
  {
    path: "/alertes",
    beforeEnter: requireAuth,
    component: ActiveLayout,
    children: [
      {
        path: "",
        name: "Alertes",
        component: Alertes
      }
    ]
  },
  {
    path: "/statistiques",
    beforeEnter: requireAuth,
    component: ActiveLayout,
    children: [
      {
        path: "",
        name: "Prestations mensuelles",
        component: Facturation
      }
    ]
  },
  {
    path: "/debug",
    beforeEnter: requireAuth,
    component: ActiveLayout,
    children: [
      {
        path: "",
        name: "Debug",
        component: Test
      }
    ]
  },
  {
    path: "/statistiques/:query/:title/:dateDebut/:dateFin",
    name: "Generation Statistique",
    component: StatPrint
  },
  {
    path: "*",
    redirect: "/overview"
  }
  // {
  //   path: "/indicators",
  //   component: ActiveLayout,
  //   beforeEnter: requireAuth,
  //   children: [
  //     {
  //       path: "",
  //       name: "Indicateurs",
  //       component: Indicators
  //     }
  //   ]
  // },
  // {
  //   path: "/flow/incoming",
  //   component: ActiveLayout,
  //   beforeEnter: requireAuth,
  //   children: [
  //     {
  //       path: "",
  //       name: "Gestion des flux / Flux entrants",
  //       component: Flux
  //     }
  //   ]
  // },
  // {
  //   path: "/flow/outgoing",
  //   component: ActiveLayout,
  //   beforeEnter: requireAuth,
  //   children: [
  //     {
  //       path: "",
  //       name: "Gestion des flux / Flux sortants",
  //       component: Flux,
  //       props: { outgoing: true }
  //     }
  //   ]
  // },
];

export default SharedRoutes.concat(AdminRoutes, UserRoutes);
