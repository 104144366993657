<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-data-table
        :loading="loading"
        :headers="columns.filter(c => !ignore_columns.includes(c.value))"
        :items="rows"
        hide-default-footer
        disable-pagination
      >
        <template v-slot:header.libelle>
          Libellé
        </template>
        <template v-slot:header.montant>
          Quantité
        </template>
        <template v-slot:header.prix_unitaire>
          Prix unitaire
        </template>
        <template v-slot:header.total>
          Total
        </template>
        <template v-slot:item="{ item }">
          <tr>
            <template v-for="(c, i) in item">
              <td v-if="!ignore_columns.includes(i)" :key="c.id">
                <template v-if="i === 'libelle'">
                  {{ c.replaceAll("_", " ") }}
                </template>
                <template
                  v-else-if="i === 'total' && item['prix_unitaire'] !== ''"
                >
                  <b>{{ numeralFormat(getTotal(item)) }} €</b>
                </template>
                <template v-else-if="i === 'montant' || i === 'prix_unitaire'">
                  <v-text-field
                    v-model="item[i]"
                    dense
                    type="number"
                    :label="getPlaceholder(i)"
                    class="mt-5"
                  />
                </template>
                <template v-else>
                  <v-text-field
                    v-model="item[i]"
                    dense
                    type="number"
                    :label="getPlaceholder(i)"
                    suffix="€"
                    class="mt-5"
                  />
                </template>
              </td>
            </template>
          </tr>
        </template>
        <template v-slot:body.append>
          <tr>
            <td class="font-weight-bold">Total</td>
            <td></td>
            <td></td>
            <td class="font-weight-bold">{{ numeralFormat(allTotal) }} €</td>
          </tr>
          <tr>
            <td>Ventes à BTE Market</td>
            <td>
              <v-text-field
                v-model="ventes_bte.unites"
                label="Unités"
                type="number"
              />
            </td>
            <td>
              <v-text-field
                v-model="ventes_bte.montant"
                label="Montant total"
                type="number"
                suffix="€"
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Coût RSE</td>
            <td>
              <v-text-field
                v-model="cout_rse.neuf"
                label="Neuf"
                type="number"
              />
            </td>
            <td>
              <v-text-field
                v-model="cout_rse.reconditionne"
                label="Reconditionné"
                type="number"
              />
            </td>
            <td></td>
          </tr>
          <tr>
            <td>Recettes</td>
            <td></td>
            <td></td>
            <td>
              <v-text-field
                v-model="recettes.montant"
                label="Recettes (montant par mois)"
                type="number"
                suffix="€"
              />
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-col>
    <v-col class="mt-3" cols="12">
      <v-col cols="12" class="d-flex justify-end">
        <v-btn
          :loading="save_loading"
          :disabled="!isDifferent"
          color="primary"
          @click="save()"
          >Valider les modifications
        </v-btn>
      </v-col>
    </v-col>
  </v-row>
</template>

<script>
import DatasetService from "@/services/DatasetService";
import QueryService from "@/services/QueryService";
import Constant from "@/constant";
import moment from "moment";
import Numeral from "@/plugins/numeral.js";
import _ from "lodash";

export default {
  name: "TableauFacture",
  props: {
    date: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    datasetHistorique: Constant.datasetFacturationHistorique.id,
    datasetFacturationPrixUnitaire:
      Constant.datasetFacturationPrixUnitaire.id,
    datasetFacturationBte: Constant.datasetFacturationBte.id,
    query_facturation: Constant.query_facturation.id,
    query_headers: Constant.query_headersFacturation.id,
    loading: false,
    columns: [],
    rows: [],
    old_rows: [],
    ignore_columns: ["primary_key", "month", "year"],
    libelles: [
      "Nombre de matériels reçus",
      "Matériels <= 3 Kg testés OK renvoyés",
      "Matériels > 3 Kg testés OK renvoyés",
      "Cartes testées OK renvoyées",
      "Matériels Swappés renvoyés",
      "Alimentation fournies par Infonegoce",
      "Accessoires remplacés",
      "Réparation de CPEs",
      "Coût de transport",
      "Fiches requalifiées",
      "Facturation complémentaire"
    ],
    save_loading: false,
    allTotal: 0,
    added_line_default_form: {
      libelle: "",
      montant: "",
      prix_unitaire: "",
      total: ""
    },
    added_lines: [
      {
        libelle: "",
        montant: "",
        prix_unitaire: "",
        total: ""
      }
    ],
    old_added_lines: [
      {
        libelle: "",
        montant: "",
        prix_unitaire: "",
        total: ""
      }
    ],
    month: null,
    year: null,
    old_recettes: {
      montant: null,
      date: null,
      primary_key: null
    },
    recettes: {
      montant: null,
      date: null,
      primary_key: null
    },
    ventes_bte: {
      montant: null,
      unites: null,
      date: null,
      primary_key: null
    },
    old_ventes_bte: {
      montant: null,
      unites: null,
      date: null,
      primary_key: null
    },
    cout_rse: {
      neuf: null,
      reconditionne: null,
      date: null,
      primary_key: null
    },
    old_cout_rse: {
      neuf: null,
      reconditionne: null,
      date: null,
      primary_key: null
    }
  }),
  computed: {
    parameters() {
      return [
        {
          type: "Number",
          name: "year",
          value: this.year
        },
        {
          type: "Number",
          name: "month",
          value: this.month
        }
      ];
    },
    isDifferent() {
      const different =
        !_.isEqual(this.rows, this.old_rows) ||
        !_.isEqual(this.added_lines, this.old_added_lines) ||
        !_.isEqual(this.recettes, this.old_recettes) ||
        !_.isEqual(this.ventes_bte, this.old_ventes_bte) ||
        !_.isEqual(this.cout_rse, this.old_cout_rse);
      this.$emit("modification", different);
      return different;
    },
    difference() {
      const buff = [];
      this.rows.forEach((r, index) => {
        if (!_.isEqual(r, this.old_rows[index])) {
          buff.push(r);
        }
      });
      return buff;
    }
  },
  watch: {
    date: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.month = newVal.month;
        this.year = newVal.year;
        this.getData();
      }
    },
    rows: {
      deep: true,
      handler() {
        this.allTotal = this.getAllTotal();
      }
    },
    added_lines: {
      deep: true,
      handler() {
        const isEmpty = this.added_lines.map(r => this.isAddedLineEmpty(r));
        isEmpty.forEach((bool, index) => {
          if (bool && index < this.added_lines.length - 1) {
            this.added_lines.splice(index, 1);
          }
        });
        // Uncomment to add more lines
        // if (!isEmpty[isEmpty.length - 1]) {
        //   this.added_lines.push(_.cloneDeep(this.added_line_default_form));
        // }
        this.allTotal = this.getAllTotal();
      }
    }
  },
  methods: {
    async getData() {
      this.loading = true;
      const results = await QueryService.run(
        this,
        this.query_facturation,
        this.parameters
      );
      this.columns = results.data.columns.map(c => {
        return {
          text: c.name,
          value: c.name,
          align: "start",
          sortable: false,
          width: this.calculateWidthColumns(c.name),
          class: this.ignore_columns.includes(c.name) ? "d-none" : ""
        };
      });

      // Change null to '', to not trigger isDifferent if you modify the value then delete what you put
      results.data.rows = this.dirtyRows(results.data.rows);

      this.rows = results.data.rows;
      if (this.rows.length === 0) {
        // No facture for this month
        await this.fillThisMonth();
        return;
      }

      // Sort result by libelle in a specific order defined in the spec
      this.libelles.forEach((lib, index) => {
        const indexResult = this.rows.findIndex(r => r.libelle === lib);
        if (index !== indexResult && indexResult !== -1) {
          const buff = this.rows[index];
          this.rows[index] = this.rows[indexResult];
          this.rows[indexResult] = buff;
        }
      });
      this.rows = this.rows.filter(r => r !== undefined);

      this.old_rows = _.cloneDeep(this.rows);

      await this.getRecettes();
      await this.getVentes();
      await this.getCoutRse();

      this.loading = false;
    },
    async fillThisMonth() {
      // Fill a facture for this month with blank and default template
      const resultHeader = await QueryService.run(this, this.query_headers);
      let buildedRows = null;
      if (
        this.year === moment().year() &&
        this.month === moment().month() + 1
      ) {
        buildedRows = resultHeader.data.rows.map(r => {
          return {
            libelle: r.libelle,
            montant: r.montant ?? null,
            prix_unitaire: r.prix_unitaire ?? null,
            total: this.getTotal({
              total: null,
              prix_unitaire: r.prix_unitaire ?? null,
              montant: r.montant ?? null
            }),
            month: this.month,
            year: this.year
          };
        });
      } else {
        buildedRows = resultHeader.data.rows.map(r => {
          return {
            libelle: r.libelle,
            montant: null,
            prix_unitaire: r.prix_unitaire ?? null,
            total: null,
            month: this.month,
            year: this.year
          };
        });
      }

      buildedRows.push({
        libelle: "Facturation complémentaire",
        montant: null,
        prix_unitaire: null,
        total: null,
        month: this.month,
        year: this.year
      });

      const promises = [];
      buildedRows.forEach(r => {
        promises.push(
          DatasetService.create(this, this.datasetHistorique, r, false)
        );
      });
      await Promise.all(promises);

      await this.getData();
    },
    getTotal(row) {
      // Get the total of a row
      const montant = row.montant ?? 0;
      const prixUnitaire = row.prix_unitaire ?? 1;

      if (row.prix_unitaire) {
        return Math.round(montant * prixUnitaire * 100) / 100;
      } else if (row.total !== "0" && row.total !== "") {
        return Number(row.total);
      } else if (row.montant) {
        return Number(row.total);
      } else {
        return null;
      }
    },
    dirtyRows(rows) {
      // Change null to '', to not trigger isDifferent if you putted something in text field then deleted it
      rows = rows.map(r => {
        for (const key of Object.keys(r)) {
          if (r[key] === null) r[key] = "";
        }
        return r;
      });
      return rows;
    },
    cleanRow(rows) {
      // Change '' to null, '' was to not trigger isDifferent if you putted something in text field then deleted it
      rows = rows.map(r => {
        for (const key of Object.keys(r)) {
          if (r[key] === "") r[key] = null;
        }
        r.total = this.getTotal(r);
        return r;
      });
      return rows;
    },
    async save() {
      // Save to the backend the facture
      this.save_loading = true;

      let buffRows = _.cloneDeep(this.difference);
      // this.rows is cloneDeeped we can now modify safely this.rows without modifying the data saved
      const promiseAddedLines = this.saveAddedLines(); // This function modify this.rows <- cloneDeep before this
      const promiseDeleteAddedLines = this.deleteEmptySavedLine();
      const promiseRecettes = this.saveRecettes();
      const promiseVentes = this.saveVentes();
      const promiseCoutRse = this.saveCoutRse();

      buffRows = buffRows.filter(r => !this.isAddedLineEmpty(r)); // save() should not try to save empty added line,
      // deleteEmptySavedLine is already deleting them in the same time
      buffRows = this.cleanRow(buffRows);

      const fields = await DatasetService.get_informations(
        this,
        this.datasetHistorique
      );
      const promises = [];
      buffRows.forEach(r => {
        promises.push(
          DatasetService.update(this, this.datasetHistorique, r, fields, false)
        );
      });

      await Promise.all(promises);
      await promiseAddedLines;
      await promiseDeleteAddedLines;
      await promiseRecettes;
      await promiseVentes;

      await this.getData();

      this.$store.commit("success", "La facture a été mise à jour");
      this.save_loading = false;
    },
    async saveAddedLines() {
      let addedLineNotEmpty = [];
      this.added_lines
        .map(r => !this.isAddedLineEmpty(r))
        .forEach((bool, index) => {
          if (bool) addedLineNotEmpty.push(this.added_lines[index]);
        });

      addedLineNotEmpty.forEach(r => {
        r.month = this.month;
        r.year = this.year;
        r.total = this.getTotal(r);
      });
      addedLineNotEmpty = this.cleanRow(addedLineNotEmpty);

      const promises = [];
      addedLineNotEmpty.forEach(r => {
        promises.push(
          DatasetService.create(this, this.datasetHistorique, r, false)
        );
      });

      await Promise.all(promises);

      this.added_lines = _.cloneDeep(this.old_added_lines);

      return true;
    },
    async saveRecettes() {
      try {
        if (this.recettes.primary_key) {
          let fields = await DatasetService.get_informations(
            this,
            Constant.datasetRecettes.id
          );
          fields = fields.data.fields;
          await DatasetService.update(
            this,
            Constant.datasetRecettes.id,
            {
              montant: this.recettes.montant ?? 0,
              year: this.year,
              month: this.month,
              primary_key: this.recettes.primary_key
            },
            fields,
            false
          );
        } else {
          await DatasetService.create(
            this,
            Constant.datasetRecettes.id,
            {
              montant: this.recettes.montant ?? 0,
              year: this.year,
              month: this.month
            },
            false
          );
        }
        this.old_recettes = this.recettes;
      } catch (error) {
        console.error(error);
      }
    },
    async saveVentes() {
      try {
        if (this.ventes_bte.primary_key) {
          let fields = await DatasetService.get_informations(
            this,
            Constant.datasetVentesBte.id
          );
          fields = fields.data.fields;
          await DatasetService.update(
            this,
            Constant.datasetVentesBte.id,
            {
              montant: this.ventes_bte.montant ?? 0,
              unites: this.ventes_bte.unites ?? 0,
              year: this.year,
              month: this.month,
              primary_key: this.ventes_bte.primary_key
            },
            fields,
            false
          );
        } else {
          await DatasetService.create(
            this,
            Constant.datasetVentesBte.id,
            {
              montant: this.ventes_bte.montant ?? 0,
              unites: this.ventes_bte.unites ?? 0,
              year: this.year,
              month: this.month
            },
            false
          );
        }
        this.old_ventes_bte = this.ventes_bte;
      } catch (error) {
        console.error(error);
      }
    },
    async saveCoutRse() {
      try {
        if (this.cout_rse.primary_key) {
          let fields = await DatasetService.get_informations(
            this,
            Constant.datasetCoutRse.id
          );
          fields = fields.data.fields;
          await DatasetService.update(
            this,
            Constant.datasetCoutRse.id,
            {
              neuf: this.cout_rse.neuf ?? 0,
              reconditionne: this.cout_rse.reconditionne ?? 0,
              year: this.year,
              month: this.month,
              primary_key: this.cout_rse.primary_key
            },
            fields,
            false
          );
        } else {
          await DatasetService.create(
            this,
            Constant.datasetCoutRse.id,
            {
              neuf: this.cout_rse.neuf ?? 0,
              reconditionne: this.cout_rse.reconditionne ?? 0,
              year: this.year,
              month: this.month
            },
            false
          );
        }
        this.old_cout_rse = this.cout_rse;
      } catch (error) {
        console.error(error);
      }
    },
    async deleteEmptySavedLine() {
      // Delete saved added line that was filled but now are empty
      let emptyRows = [];

      this.rows
        .map(r => this.isAddedLineEmpty(r))
        .forEach((bool, index) => {
          if (bool) emptyRows.push(_.cloneDeep(this.rows[index]));
        });
      emptyRows = this.cleanRow(emptyRows);

      const promises = [];

      let fields = await DatasetService.get_informations(
        this,
        this.datasetHistorique
      );
      fields = fields.data.fields;

      emptyRows.forEach(r => {
        promises.push(
          DatasetService.deleteRow(this, this.datasetHistorique, r, fields)
        );
      });

      await Promise.all(promises);

      return true;
    },
    async getRecettes() {
      let result = await QueryService.run(this, Constant.query_recettes.name);
      if (!result) {
        return;
      }

      if (this.month < 10 && this.month.toString().length === 1) {
        this.month = `0${this.month}`;
      }

      result = result.data.rows;
      result = result.filter(r => {
        return r.date === `${this.year}/${this.month}`;
      });
      this.recettes =
        result.length === 0
          ? {
              montant: null,
              date: null,
              primary_key: null
            }
          : _.cloneDeep(result[0]);
      this.old_recettes =
        result.length === 0
          ? {
              montant: null,
              date: null,
              primary_key: null
            }
          : _.cloneDeep(result[0]);
    },
    async getVentes() {
      let result = await QueryService.run(this, Constant.query_ventes_bte.name);
      if (!result) {
        return;
      }

      if (this.month < 10 && this.month.toString().length === 1) {
        this.month = `0${this.month}`;
      }

      result = result.data.rows;
      result = result.filter(r => {
        return r.date === `${this.year}/${this.month}`;
      });
      this.ventes_bte =
        result.length === 0
          ? {
              montant: null,
              unites: null,
              date: null,
              primary_key: null
            }
          : _.cloneDeep(result[0]);
      this.old_ventes_bte =
        result.length === 0
          ? {
              montant: null,
              unites: null,
              date: null,
              primary_key: null
            }
          : _.cloneDeep(result[0]);
    },
    async getCoutRse() {
      let result = await QueryService.run(this, Constant.query_cout_rse.name);
      if (!result) {
        return;
      }

      result = result.data.rows;
      result = result.filter(r => {
        return r.date === `${this.year}/${this.month}`;
      });
      this.cout_rse =
        result.length === 0
          ? {
              neuf: null,
              reconditionne: null,
              date: null,
              primary_key: null
            }
          : _.cloneDeep(
              result.map(r => {
                return {
                  ...r,
                  neuf: r.Neuf,
                  reconditionne: r["Reconditionné"]
                };
              })[0]
            );
      this.old_cout_rse =
        result.length === 0
          ? {
              neuf: null,
              reconditionne: null,
              date: null,
              primary_key: null
            }
          : _.cloneDeep(
              result.map(r => {
                return {
                  ...r,
                  neuf: r.Neuf,
                  reconditionne: r["Reconditionné"]
                };
              })[0]
            );
    },
    getAllTotal() {
      // Get the total of all totals
      let total = _.sum(this.rows.map(r => this.getTotal(r)));
      total += _.sum(this.added_lines.map(r => this.getTotal(r)));
      return Math.round(total * 100) / 100;
    },
    isAddedLineEmpty(addedLine) {
      return (
        addedLine.libelle === "" &&
        addedLine.montant === "" &&
        addedLine.prix_unitaire === "" &&
        addedLine.total === ""
      );
    },
    calculateWidthColumns(column) {
      switch (column) {
        case "libelle":
          return "35%";
        case "total":
          return "25%";
        default:
          return "20%";
      }
    },
    getPlaceholder(column) {
      switch (column) {
        case "prix_unitaire":
          return "Prix unitaire";
        case "montant":
          return "Quantité";
        case "total":
          return "Total";
        case "libelle":
          return "Libelle";
        default:
          return column;
      }
    },
    numeralFormat(value) {
      return Numeral(value).format("0,0.00");
    }
  }
};
</script>
