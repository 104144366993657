<template>
  <v-container>
    <div>
      <v-row>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="client"
            v-model="dataFilters.client"
            dense
            label="Nom du client"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="rma"
            v-model="dataFilters.rma"
            dense
            label="OU Numéro RMA"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="num_serie_recu"
            v-model="dataFilters.num_serie_recu"
            dense
            label="OU Numéro de série reçu"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="imei_recu"
            v-model="dataFilters.imei_recu"
            dense
            label="OU IMEI"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="ticket"
            v-model="dataFilters.ticket"
            dense
            label="OU Ticket"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="lien_client"
            v-model="dataFilters.lien_client"
            dense
            label="OU MSISDN / Numéro de Lien"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col lg="6" sm="12">
          <v-text-field
            ref="num_bt_chrono"
            v-model="dataFilters.num_bt_chrono"
            dense
            label="OU N° suivi transport"
            :rules="[rules.isFiveOrMore]"
            @keyup.enter="filter"
          />
        </v-col>
      </v-row>
      <v-btn
        color="primary"
        class="text-right my-3"
        block
        :disabled="!oneFieldFilled"
        :loading="loading_search"
        @click="filter"
        >Rechercher</v-btn
      >
      <v-btn class="my-3" color="grey lighten-2" block @click="reset">
        x Effacer
      </v-btn>

      <v-row class="mt-10 text-center">
        <v-col cols="12">
          <v-row class="align-center">
            <v-col cols="4"></v-col>
            <v-col v-if="dataFiltered.length == 0 && anyFilterApplied" cols="4">
              Aucun résultat
            </v-col>
            <v-spacer />
            <v-btn
              v-if="dataFiltered.length > 0"
              class="filter_btn black--text my-3 mx-6 white--text"
              small
              elevation="0"
              @click="exportCsv"
            >
              Exporter
              <v-icon class="primary--text">mdi-tray-arrow-up</v-icon>
            </v-btn>
          </v-row>
        </v-col>
        <v-col v-if="dataFiltered.length > 0" cols="12">
          <v-data-table
            :items="dataFiltered"
            :loading="loading_search"
            :server-items-length="dataCount"
            :items-per-page="20"
            :options.sync="options"
            :headers="data.headers"
            :disable-sort="true"
            class="elevation-0 rounded-xl"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
          />
        </v-col>
      </v-row>
      <v-row id="timeline">
        <v-dialog v-model="dialog" max-width="800px">
          <v-container v-if="selected" class="pa-0">
            <v-card class="pa-6 pb-0">
              <h2>{{ selected.reference_recu }}</h2>
              <v-divider />
              <v-timeline dense max-width="fit-content">
                <!-- Transport -->
                <v-timeline-item
                  v-if="selected.date_envoi_bt_chrono"
                  color="#FF9F00"
                  width="fit-content"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 transport white--text">
                      Transport
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Date transport : {{ selected["date_envoi_bt_chrono"] }}
                      <br />
                      BT Chrono : {{ selected["num_bt_chrono"] }}
                      <br />
                      Date intégration PIG :
                      {{ selected["date_integration_pig"] ?? "Non défini" }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <!-- Expedition -->
                <v-timeline-item
                  v-if="selected.date_expedition"
                  width="fit-content"
                  color="#820263"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 expedition white--text">
                      Expédition
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Date expédition : {{ selected["date_expedition"] }}
                      <br />
                      Référence sortante : {{ selected["reference_sortant"] }}
                      <br />
                      Etat de sortie : {{ selected["etat_sortie"] }}
                      <br />
                      Client : {{ selected["client"] }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <!-- Tester -->
                <v-timeline-item
                  v-if="selected.a_tester_resil"
                  color="#EB4952"
                  width="fit-content"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 test white--text">
                      Test
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Doit etre tester : {{ selected["a_tester_resil"] }}
                      <br />
                      Etat après test : {{ selected["etat_apres_test"] }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <!-- Reparation 3 -->
                <v-timeline-item
                  v-if="selected.date_retour_reparateur3"
                  color="#A6DDED"
                  width="fit-content"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 reparation3 white--text">
                      Réparation 3
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Date de réparation :
                      {{ selected["date_retour_reparateur3"] }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <!-- Reparation 2 -->
                <v-timeline-item
                  v-if="selected.date_retour_reparateur2"
                  color="#ff9933"
                  width="fit-content"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 reperation2 white--text">
                      Réparation 2
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Date de réparation :
                      {{ selected["date_retour_reparateur2"] }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <!-- Reparation 1 -->
                <v-timeline-item
                  v-if="selected.date_retour_reparateur1"
                  color="#4EA699"
                  width="fit-content"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 reparation1 white--text">
                      Réparation 1
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Date de réparation :
                      {{ selected["date_retour_reparateur1"] }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
                <!-- Recu -->
                <v-timeline-item
                  v-if="selected.date_recu"
                  color="#009DCC"
                  width="fit-content"
                  fill-dot
                >
                  <v-card>
                    <v-card-title class="text-h6 recu white--text">
                      Reçu
                    </v-card-title>
                    <v-card-text class="mt-3">
                      Date reçu : {{ selected["date_recu"] }}<br />
                      Référence reçue : {{ selected["reference_recu"] }}<br />
                      N° de série reçu :
                      {{ selected["num_serie_recu"] }}<br />
                      N° de suivi du transport :
                      {{ selected["num_bt_chrono"] ?? "Non défini" }}
                    </v-card-text>
                  </v-card>
                </v-timeline-item>
              </v-timeline>
            </v-card>
          </v-container>
        </v-dialog>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import QueryService from "@/services/QueryService.js";
import axios from "axios";

export default {
  data() {
    return {
      data: {
        headers: [
          {
            text: "Client",
            value: "client"
          },
          {
            text: "P/N",
            value: "reference_sortant"
          },
          {
            text: "P/N prévu",
            value: "reference_prevu"
          },
          {
            text: "P/N reçu",
            value: "reference_recu"
          },
          {
            text: "N° de série prévu",
            value: "num_serie_prévu"
          },
          {
            text: "N° de série reçu",
            value: "num_serie_recu"
          },
          {
            text: "IMEI",
            value: "imei_recu"
          },
          {
            text: "N° du lien",
            value: "lien_client"
          },
          {
            text: "N° de suivi du transport",
            value: "num_bt_chrono"
          },
          {
            text: "Date de recu",
            value: "date_recu"
          }
        ],
        rows: []
      },
      options: {
        page: 1,
        itemsPerPage: 50
      },
      dataFilters: {
        client: "",
        num_serie_recu: "",
        imei_recu: "",
        ticket: "",
        rma: "",
        lien_client: "",
        num_bt_chrono: ""
      },
      anyFilterApplied: false,
      dataCount: 0,
      dataFiltered: [],
      selected: null,
      loading_search: false,
      dialog: false,
      request: null,
      rules: {
        isFiveOrMore: v =>
          v.length === 0 || v.length >= 5 || "Minimum 5 caractères ou rien"
      }
    };
  },
  computed: {
    oneFieldFilled() {
      return Object.values(this.dataFilters).some(field => {
        if (field !== "" && this.rules.isFiveOrMore(field) === true) {
          return true;
        }
        return false;
      });
    }
  },
  watch: {
    options: {
      handler() {
        if (this.search !== "") {
          this.search();
        }
      },
      deep: true
    }
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel();
      }
    },
    handleCancelToken() {
      this.cancel();
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      return axiosSource;
    },
    filter() {
      if (this.loading_search) return;
      this.selected = null;
      this.loading_search = true;
      this.search();
    },
    search() {
      const fieldToValidate = [
        this.$refs.client,
        this.$refs.rma,
        this.$refs.num_serie_recu,
        this.$refs.imei_recu,
        this.$refs.ticket,
        this.$refs.lien_client,
        this.$refs.num_bt_chrono
      ]
        .map(ref => ref.validate())
        .every(validation => validation === true);

      if (!fieldToValidate) {
        this.loading_search = false;
        return;
      }

      if (Object.values(this.dataFilters).every(value => value === "")) {
        this.loading_search = false;
        return;
      }

      const axiosSource = this.handleCancelToken();
      QueryService.serialNumber(
        this,
        this.dataFilters,
        false,
        this.options.page,
        this.options.itemsPerPage,
        axiosSource.token
      )
        .then(response => {
          this.dataFiltered = response.data.rows;
          this.dataCount = response.data.count;
        })
        .finally(() => {
          this.loading_search = false;
          this.anyFilterApplied = Object.values(this.dataFilters).some(
            value => value !== "" && value !== null && value !== undefined
          );
        });
    },
    exportCsv() {
      const axiosSource = this.handleCancelToken();
      QueryService.serialNumber(
        this,
        this.dataFilters,
        true,
        -1,
        -1,
        axiosSource.token
      );
    },
    reset() {
      this.selected = null;
      this.dataFiltered = [];
      this.dataCount = 0;
      this.dataFilters.client = "";
      this.dataFilters.num_serie_recu = "";
      this.dataFilters.imei_recu = "";
      this.dataFilters.ticket = "";
      this.dataFilters.rma = "";
      this.dataFilters.lien_client = "";
      this.dataFilters.num_bt_chrono = "";
      this.anyFilterApplied = false;
    },
    select(event) {
      this.selected = event;
      this.dialog = true;
    }
  }
};
</script>

<style lang="stylus" scoped>
recu = #009DCC
transport = #FF9F00
expedition_color = #820263
test = #EB4952
reparateur1 = #4EA699
reparateur2 = #ff9933
reparateur3 = #A6DDED

my-white = #FAFAFA
</style>

<style lang="scss" scoped>
$recu: #009dcc;
$transport: #ff9f00;
$expedition: #820263;
$test: #eb4952;
$reparateur1: #4ea699;
$reparateur2: #ff9933;
$reparateur3: #a6dded;

$my-white: #fafafa;

html {
  scroll-behavior: smooth;
}

ul {
  list-style: none;
}

.recu {
  color: $recu;
  background-color: $recu;
}

.transport {
  color: $transport;
  background-color: $transport;
}

.expedition {
  color: $expedition;
  background-color: $expedition;
}

.test {
  color: $test;
  background-color: $test;
}

.reparateur1 {
  color: $reparateur1;
  background-color: $reparateur1;
}

.reparateur2 {
  color: $reparateur2;
  background-color: $reparateur2;
}

.reparateur3 {
  color: $reparateur3;
  background-color: $reparateur3;
}

.theme--light.v-card > .v-card__text,
.theme--light.v-card .v-card__subtitle {
  color: rgba(0, 0, 0, 0.85);
}
</style>
