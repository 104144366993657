<template>
  <v-card :elevation="card ? 1 : 0" rounded="xl">
    <v-progress-linear
      v-if="loading"
      indeterminate
      color="primary"
    ></v-progress-linear>
    <v-card-text class="pa-0">
      <apexchart
        :ref="ref"
        :height="250"
        width="100%"
        :type="type"
        :options="options"
        :series="series"
      />
    </v-card-text>
  </v-card>
</template>

<script>
import Numeral from "@/plugins/numeral";
import QueryService from "@/services/QueryService.js";
import VueApexCharts from "vue-apexcharts";
import DatasetService from "@/services/DatasetService.js";
import _ from "lodash";

export default {
  components: {
    apexchart: VueApexCharts
  },
  props: {
    ignoredColumns: {
      type: Array,
      default: () => []
    },
    yAxis: {
      type: String,
      default: null
    },
    xAxis: {
      type: String,
      default: null
    },
    groupBy: {
      type: String,
      default: null
    },
    mainColumn: {
      type: String,
      default: null
    },
    card: {
      type: Boolean,
      default: false
    },
    queryName: {
      type: String,
      default: null
    },
    parameters: {
      type: Array,
      default: () => []
    },
    type: {
      type: String,
      default: "bar"
    },
    dataset: {
      type: String,
      default: null
    },
    datasetFilter: {
      type: Object,
      default: null
    },
    unsavedQueryData: {
      type: Object,
      default: () => {
        return {
          rows: [],
          columns: []
        };
      }
    },
    strokeCurve: {
      type: String,
      default: "monotoneCubic"
    },
    thinColumns: {
      type: Boolean,
      default: false
    },
    barColor: {
      type: String,
      default: "primary"
    },
    stepSize: {
      type: Number,
      default: null
    },
    formatType: {
      type: String,
      default: null
    }
  },
  data() {
    let palettes = [];
    switch (this.type) {
      case "donut":
        palettes = ["#009DCC", "#EA5B0F", "#36DAC7", "#FF9F00"];
        break;
      case "area":
        palettes = ["#2CD9C5", "#EA5B0F", "#009DCC"];
        break;
      default:
        if (this.barColor === "primary") {
          palettes = ["#EA5B0F", "#62A7FF"];
        } else if (this.barColor === "yellow") {
          palettes = ["#FF9F00"];
        } else if (this.barColor === "blue") {
          palettes = ["#009DCC"];
        } else {
          palettes = ["#62A7FF", "#EA5B0F"];
        }
        break;
    }
    return {
      ref: "char-" + Date.now(),
      loading: false,
      series: [
        {
          name: "Total",
          data: []
        }
      ],
      pieOptions: {
        chart: {
          zoom: {
            enabled: false,
            allowMouseWheelZoom: false
          }
        },
        colors: palettes,
        stroke: {
          width: 0
        },
        legend: {
          show: true,
          position: "left",
          width: 150,
          height: 100,
          horizontalAlign: "left"
        },
        plotOptions: {
          offsetY: -10
        },
        dataLabels: {
          enabled: false
        },
        responsive: [
          {
            breakpoint: 960,
            options: {
              legend: { show: true }
            }
          }
        ]
      },
      barOptions: {
        chart: {
          toolbar: {
            show: true,
            tools: {
              download: true,
              selection: false,
              zoom: false,
              zoomin: false,
              zoomout: false,
              pan: false,
              reset: false
            }
          },

          zoom: {
            enabled: false,
            allowMouseWheelZoom: false
          }
        },
        fill: {
          type: ["solid", "gradient", "gradient"],
          gradient: {
            opacityFrom: 0.45,
            opacityTo: 0
          }
        },
        markers: {
          size: 4,
          colors: ["#fff"],
          strokeColors: palettes,
          strokeWidth: 3
        },
        stroke: {
          curve: this.strokeCurve
        },
        grid: {
          strokeDashArray: this.thinColumns ? 0 : 5,
          yaxis: {
            lines: { show: !this.thinColumns }
          },
          xaxis: {
            lines: { show: true }
          }
        },
        plotOptions: {
          bar: this.thinColumns
            ? {
                columnWidth: "10%",
                borderRadius: 5
              }
            : {
                columnWidth: "60%",
                dataLabels: {
                  position: "top"
                }
              }
        },
        dataLabels: this.thinColumns
          ? { enabled: false }
          : {
              enabled: true,
              offsetY: -20,
              style: {
                fontSize: "12px",
                fontWeight: 800,
                colors: palettes
              },
              formatter: () => {}
            },
        legend: {
          position: this.queryName === "cpe_all" ? "top" : "bottom",
          markers:
            this.queryName === "cpe_all"
              ? {
                  size: 10,
                  shape: "line",
                  fillColors: ["#2CD9C5", "#EA5B0F", "#009DCC"]
                }
              : {}
        },
        yaxis: {
          stepSize: this.stepSize,
          labels: {
            show: this.queryName !== "cout_rse",
            formatter: v => v
          },
          title: {
            text: this.queryName === "cout_rse" ? "Kg/CO2" : ""
          }
        },
        xaxis: {
          type: "category",
          labels: { show: true },
          tickPlacement: this.thinColumns ? "on" : "between",
          axisBorder: { show: !this.thinColumns },
          axisTicks: { show: !this.thinColumns }
        },
        colors: palettes
      }
    };
  },
  computed: {
    options() {
      return this.type === "donut" ? this.pieOptions : this.barOptions;
    },
    palettes() {
      switch (this.type) {
        case "donut":
          return ["#009DCC", "#EA5B0F", "#36DAC7", "#FF9F00"];
        case "area":
          return ["#2CD9C5", "#EA5B0F", "#009DCC"];
        default:
          return ["#EA5B0F", "#62A7FF"];
      }
    }
  },
  watch: {
    queryName() {
      if (this.queryName != null) {
        this.get();
      }
    },
    parameters: {
      deep: true,
      immediate: true,
      handler: function(newVal) {
        this.parameters = newVal;
        this.get();
      }
    },
    dataset() {
      if (this.dataset != null) {
        this.get();
      }
    },
    datasetFilter() {
      if (this.datasetFilter != null) {
        this.get();
      }
    },
    unsavedQueryData() {
      if ("columns" in this.unsavedQueryData) {
        if (this.unsavedQueryData.columns.length > 0) {
          this.render(this.unsavedQueryData);
        }
      }
    }
  },
  mounted() {
    const $ = this;

    if ($.type === "bar") {
      if ($.formatType === "currency") {
        $.options.yaxis.labels.formatter = v => Numeral(v).format() + " €";
        $.options.dataLabels.formatter = v => Numeral(v).format() + " €";
      } else if ($.formatType === "numbers") {
        $.options.yaxis.labels.formatter = v => Numeral(v).format();
        $.options.dataLabels.formatter = v => Numeral(v).format();
      }
    }

    $.get();
  },
  methods: {
    handlePieChartData(data) {
      const $ = this;
      $.series = $.lo.map(data.rows, $.yAxis);
      $.options.labels = $.lo.map(data.rows, $.xAxis);
      if (this.$refs[$.ref]) {
        this.$refs[$.ref].updateOptions($.options);
      }
    },
    handleBarChartData(data) {
      const $ = this;
      if ($.xAxis) {
        let categories = _.map(data.rows, $.xAxis);
        categories = _.uniq(categories);
        $.options.xaxis = {
          categories: categories
        };
        $.series = $.lo.map(
          data.columns.filter(
            v => v.name !== $.xAxis && !$.ignoredColumns.includes(v.name)
          ),
          c => {
            return {
              name: c.name,
              data: $.lo.map(data.rows, r => {
                return r[c.name];
              })
            };
          }
        );
      } else {
        $.options.xaxis = {
          categories: ["Montant"]
        };
        $.series = $.lo.map(data.columns, c => {
          return {
            name: c.name,
            data: [data.rows[0][c.name]]
          };
        });
      }

      if ($.groupBy) {
        // Regroup all categories, with an array of index, that indicate which index is in which category
        const groupSeries = $.series.find(s => s.name === $.groupBy);
        let groups = groupSeries ? groupSeries.data : [];
        groups = groups.map((d, i) => {
          return {
            category: d,
            index: i
          };
        });
        groups = _.groupBy(groups, "category");
        let buff = {};
        Object.keys(groups).forEach(k => {
          buff[k] = groups[k].map(g => g.index);
        });
        groups = buff;

        // Create the grouped series that will replace series
        const buffSeries = Object.keys(groups).map(k => {
          return {
            name: k,
            data: [],
            type: "area"
          };
        });
        // Change mainColumn type to column
        const mainColumnSeries = buffSeries.find(s => s.name === $.mainColumn);
        if (mainColumnSeries) {
          mainColumnSeries.type = "line";
        }

        // Put each value in the corresponding series
        const mainSeries = $.series.find(s => s.name === $.yAxis);
        if (mainSeries) {
          mainSeries.data.forEach((d, i) => {
            const series = Object.keys(groups).filter(g =>
              groups[g].includes(i)
            )[0];
            const targetSeries = buffSeries.find(s => s.name === series);
            if (targetSeries) {
              targetSeries.data.push(d);
            }
          });
        }
        mainSeries.data.forEach((d, i) => {
          const series = Object.keys(groups).filter(g =>
            groups[g].includes(i)
          )[0];
          const targetSeries = buffSeries.find(s => s.name === series);
          if (targetSeries) {
            targetSeries.data.push(d);
          }
        });

        // Order series
        const mainIndex = _.findIndex(buffSeries, s => s.name === $.mainColumn);
        buff = buffSeries[mainIndex];
        buffSeries[mainIndex] = buffSeries[0];
        buffSeries[0] = buff;

        // Replace series with our grouped series
        $.series = buffSeries;
      }
      if (this.$refs[$.ref]) {
        this.$refs[$.ref].updateOptions($.options);
      }
    },
    get() {
      this.loading = true;
      if (this.queryName) {
        QueryService.run(this, this.queryName, this.parameters)
          .then(r => {
            this.render(r.data);
          })
          .finally(() => {
            this.loading = false;
          });
      } else if (this.dataset) {
        /**
         * Permet d'utiliser le Dataset plutôt que la query, plus utile dans certains cas
         */
        const param = this.datasetFilter ? this.datasetFilter : "";
        const header = "";
        DatasetService.get(this, this.dataset, param, header)
          .then(result => {
            // Traitement de la data pour avoir column [x, y]
            // row [{x, y},...]
            const column = [{ name: this.xAxis }, { name: this.yAxis }];
            const rows = [];
            for (let elem in result.data) {
              elem = result.data[elem];
              const temp = {};
              temp[column[0].name] = elem[column[0].name];
              temp[column[1].name] = elem[column[1].name];
              rows.push(temp);
            }

            const data = {
              rows: rows,
              columns: column
            };
            this.render(data);
          })
          .catch(error => {
            this.$store.commit("error", {
              msg: error,
              color: "error"
            });
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    render(data) {
      switch (this.type) {
        case "bar":
        case "area":
          this.handleBarChartData(data);
          break;
        case "donut":
          this.handlePieChartData(data);
          break;
        default:
          break;
      }
    }
  }
};
</script>

<style scoped></style>
