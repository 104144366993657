/* eslint-disable space-before-function-paren */
import { HTTP, DefaultErrorHandler } from "@/utils/http-common";
import QueryService from "./QueryService";
import Constant from "../constant";

export default {
  upload: function($, file, projectId) {
    return new Promise((resolve, reject) => {
      var formData = new FormData();
      formData.append("file", file);
      formData.append("project_id", projectId);

      HTTP.post("/files/manage/", formData, {
        headers: {
          "Content-Type": "multipart/form-data"
        }
      })
        .then(response => {
          const emailBodyvariables = {
            id: response.data.id,
            original_name: response.data.original_name,
            uploaded_at: response.data.uploaded_at,
            uploaded_by: response.data.uploaded_by,
            url: response.data.url
          };
          this.sendEmail(
            $,
            emailBodyvariables,
            `Nouvelle demande de traitement de ${response.data.uploaded_by}`
          );
          resolve(response.data);
        })
        .catch(err => {
          $.$store.commit("error", {
            msg: "Vous n'avez pas la permission d'effectuer cette action.",
            color: "error"
          });
          reject(err);
        });
    });
  },
  getAll(
    $,
    startDateFilter = null,
    endDateFilter = null,
    page = 1,
    pageSize = 10
  ) {
    const searchParams = {
      page: page,
      page_size: pageSize,
      start_date_filter: startDateFilter,
      end_date_filter: endDateFilter
    };

    const p =
      "?" +
      $.lo.join(
        $.lo.map(searchParams, (o, i) => {
          return i + "=" + (typeof o === "object" ? JSON.stringify(o) : o);
        }),
        "&"
      );

    return new Promise((resolve, reject) => {
      HTTP.get(`/bytel/events/${p.replace("?", "") ? p : ""}`)
        .then(r => {
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  searchAll(
    $,
    column,
    value,
    page,
    pageSize,
    startDateFilter = null,
    endDateFilter = null
  ) {
    const searchParams = {
      column_name: column,
      page: page,
      page_size: pageSize,
      filter_value: value,
      start_date_filter: startDateFilter,
      end_date_filter: endDateFilter
    };

    const p =
      "?" +
      $.lo.join(
        $.lo.map(searchParams, (o, i) => {
          return i + "=" + (typeof o === "object" ? JSON.stringify(o) : o);
        }),
        "&"
      );

    return new Promise((resolve, reject) => {
      HTTP.get(`/bytel/events/search/${p.replace("?", "") ? p : ""}`)
        .then(r => {
          resolve(r.data);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  download($, file) {
    return new Promise((resolve, reject) => {
      HTTP.get("/bytel/event/" + file.id + "/", {
        responseType: "arraybuffer"
      })
        .then(r => {
          const blob = new Blob([r.data], { type: r.headers["content-type"] });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          resolve(link);
        })
        .catch(err => DefaultErrorHandler($, err, reject));
    });
  },
  jsonToCSV($, json) {
    if (json.length > 0) {
      const headers = Object.keys(json[0])
        .toString()
        .replace(/,/g, ";");
      const csv = json.map(row => {
        return Object.values(row).join(";");
      });
      csv.unshift(headers);

      return csv.join("\n");
    }
  },
  downloadFile($, csv, documentRef, filename) {
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });

    const url = URL.createObjectURL(blob);

    const link = documentRef.createElement("a");

    link.setAttribute("href", url);

    link.setAttribute("download", filename);

    link.style.display = "none";

    documentRef.body.appendChild(link);

    link.click();

    documentRef.body.removeChild(link);
  },
  sendEmail: function($, bodyVariables, subject) {
    return new Promise((resolve, reject) => {
      HTTP.post("files/manage/sendmail/", {
        body_variables: bodyVariables,
        subject: subject
      })
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err);
        });
    });
  }
};
