<template>
  <!-- INFO : This view is shared among Stock page AND Orders History page -->
  <!-- This is the same component for both pages, with different values -->
  <!-- Because it was initially supposed to be the exact same layout with different values -->
  <div>
    <v-row>
      <v-col cols="3" align-self="center">
        <v-text-field
          v-model="search"
          label="Recherche par P/N"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-spacer></v-spacer>
      <export-csv
        class="mt-8"
        :filename="isHistory ? 'historique_commandes' : 'suivi_stocks'"
        :dataset-id="isHistory ? dataset_historique_id : dataset_stocks_id"
      />
      <v-dialog v-model="dialog" max-width="700px" style="display: flex">
        <v-card
          class="rounded-xl"
          style="display: flex; flex-direction: column"
        >
          <v-card-title>
            <v-col cols="5">
              <span class="text-h6 primary--text">Stocks CIRCET</span>
            </v-col>
            <v-col cols="7" class="text-right">
              <v-btn icon class="primary--text" @click="close">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-card-title>
          <v-card-subtitle class="ml-3 mt-n8">
            <span class="text-subtitle-1">
              sur la référence
              <span class="font-weight-medium">
                {{ dialogData?.part_number }}
              </span>
            </span>
          </v-card-subtitle>
          <p v-if="!actualLocationCodes.length" style="padding: 2rem">
            <span v-if="dialogData?.stock_circet">
              Localisation des stocks indisponible.
            </span>
            <span v-else>
              Aucun détail sur le stock CIRCET.
            </span>
          </p>
          <v-data-table
            v-else
            :items="actualLocationCodes"
            :headers="[
              { title: 'Emplacement', value: 'location' },
              { title: 'Quantité', value: 'quantity' }
            ]"
          />
        </v-card>
      </v-dialog>
    </v-row>
    <v-card class="rounded-lg pa-5" elevation="0">
      <v-data-table
        :items="tableData"
        :headers="headers"
        :loading="isHistory ? history_loading : stock_loading"
        :options.sync="options"
        :server-items-length="itemsCount"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100]
        }"
        @click:row="openDialogForRow"
      >
        <template v-slot:item.traite_plurium="{ item }">
          <v-chip
            :color="
              item.traite_plurium
                ? 'tableChipValid px-10'
                : 'tableChipInvalid px-8'
            "
          >
            {{ item.traite_plurium ? "Traité" : "En cours" }}
          </v-chip>
        </template>
        <template v-slot:item.commandee_le="{ item }">
          {{ formatDate(item.commandee_le) }}
        </template>
        <template v-slot:item.button="{ item }">
          <place-order :profile="profile" :item="item" @closed="searchStocks" />
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import PlaceOrder from "@/components/bytel/PlaceOrder";
import ExportCsvButton from "@/components/hmd/ExportCsvButton";
import Constant from "@/constant.js";
import DatasetService from "@/services/DatasetService.js";
import AccountService from "@/services/AccountService.js";
import axios from "axios";

export default {
  components: {
    "place-order": PlaceOrder,
    "export-csv": ExportCsvButton
  },
  props: {
    isHistory: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      history: [],
      stocks: [],
      headersHistory: [
        { 
          text: "P/N",
          value: "produit",
          align: "center",
          sortable: false
        },
        {
          text: "Quantité",
          value: "quantite",
          align: "center",
          filterable: false,
          sortable: false
        },
        {
          text: "Demandeur",
          value: "demandeur",
          align: "center",
          filterable: false,
          sortable: false
        },
        {
          text: "Commande envoyée le :",
          value: "commandee_le",
          align: "center",
          filterable: false,
          sortable: false
        }
      ],
      headersStock: [
        {
          text: "P/N",
          value: "reference",
          align: "center",
          filterable: false
        },
        {
          text: "Stock Circet",
          value: "stock_circet",
          align: "center",
          filterable: false,
          sortable: false
        },
        {
          text: "Stock Plurium",
          value: "en_stock",
          align: "center",
          filterable: false,
          sortable: false
        }
      ],
      search: "",
      hasBeenModified: false,
      loading_save: false,
      dataset_historique_id: Constant.datasetHistoriqueCommandes.id,
      dataset_stocks_id: Constant.datasetSuiviStock.id,
      debounceTimer: null,
      stock_loading: false,
      history_loading: false,
      profile: {
        email: "",
        first_name: "",
        last_name: ""
      },
      itemsCountStock: 0,
      itemsCountHistory: 0,
      options: {
        page: 1,
        itemsPerPage: 50
      },
      dialogData: {},
      dialog: false,
      group: "",
      admins: [...Constant.super_admin_groups()],
      request: null
    };
  },
  computed: {
    isSuperAdmin() {
      return this.admins.includes(this.group);
    },
    actualLocationCodes() {
      try {
        let locations = [];
        if (typeof this.dialogData?.actual_location_codes === "object") {
          locations = this.dialogData.actual_location_codes;
        } else if (typeof this.dialogData?.actual_location_codes === "string") {
          locations = JSON.parse(
            this.dialogData.actual_location_codes.replace(/'/g, '"')
          );
        }
        return Object.entries(locations).map(([key, value]) => ({
          location: key,
          quantity: value
        }));
      } catch {
        return [];
      }
    },
    tableData() {
      return this.isHistory ? this.history : this.stocks;
    },
    headers() {
      if (this.isHistory) {
        return this.headersHistory;
      } else if (this.isSuperAdmin) {
        return this.headersStockSuperAdmin();
      }
      return this.headersStock;
    },
    itemsCount() {
      return this.isHistory ? this.itemsCountHistory : this.itemsCountStock;
    },
    totalStock() {
      return this.dialogData?.quantité && this.dialogData?.stock_circet
        ? this.dialogData?.quantité + this.dialogData?.stock_circet
        : this.dialogData?.quantité ?? this.dialogData?.stock_circet ?? 0;
    }
  },
  watch: {
    search() {
      this.searchStocks();
    },
    options: {
      handler() {
        this.searchStocks();
      },
      deep: true
    },
    isHistory() {
      this.options.page = 1;
      this.clearTables();
      this.get();
    }
  },
  created() {
    this.group = this.$store.getters.group;

    const t = this;
    AccountService.profile(this).then(function(response) {
      t.profile = response;
      t.profileCopy = t.lo.cloneDeep(response);
    });
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel();
      }
    },
    handleCancelToken() {
      this.cancel();
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      return axiosSource;
    },
    openDialogForRow(event) {
      if (this.isHistory) {
        return;
      }
      this.dialogData = event;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    headersStockSuperAdmin() {
      return [
        ...this.headersStock,
        {
          text: "Passer commande",
          value: "button",
          align: "center",
          filterable: false,
          sortable: false
        }
      ];
    },
    get() {
      const { page, itemsPerPage } = this.options;

      const axiosSource = this.handleCancelToken();

      let sort = [];

      if (this.options.sortBy.length > 0) {
        sort = [
          {
            field_name: "reference",
            order: this.options.sortDesc[0] ? "desc" : "asc"
          }
        ];
      }

      if (this.isHistory) {
        this.history_loading = true;
        DatasetService.get(
          this,
          this.dataset_historique_id,
          {},
          {},
          page,
          itemsPerPage,
          axiosSource.token
        )
          .then(response => {
            this.itemsCountHistory = response.data.count;
            response.data = response.data.results;
            this.history = response.data.map(row => {
              return {
                ...row,
                commandee_le: new Date(row.commandee_le)
              };
            });
          })
          .finally(() => {
            this.history_loading = false;
          });
      } else {
        this.stock_loading = true;
        DatasetService.get(
          this,
          this.dataset_stocks_id,
          {},
          {},
          page,
          itemsPerPage,
          axiosSource.token,
          sort
        )
          .then(response => {
            this.itemsCountStock = response.data.count;
            response.data = response.data.results;
            this.stocks = response.data;
          })
          .finally(() => {
            this.stock_loading = false;
          });
      }
    },
    searchStocks() {
      const headers = "";
      this.options.page = 1;
      const { page, itemsPerPage } = this.options;

      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        if (this.search === "") {
          this.get();
          return;
        }
        this.stock_loading = true;
        this.history_loading = true;
        const header = "";
        const axiosSource = this.handleCancelToken();

        let sort = [];

        if (this.options.sortBy.length > 0) {
          sort = [
            {
              field_name: "reference",
              order: this.options.sortDesc[0] ? "desc" : "asc"
            }
          ];
        }

        DatasetService.search(
          this,
          Constant.datasetHistoriqueCommandes.id,
          headers,
          "produit",
          this.search,
          page,
          itemsPerPage,
          axiosSource.token
        ).then(response => {
          this.itemsCountHistory = response.data.count;
          this.history = response.data.results.map(row => {
            return {
              ...row,
              commandee_le: new Date(row.commandee_le)
            };
          });
          this.history_loading = false;
        });
        DatasetService.search(
          this,
          Constant.datasetSuiviStock.id,
          headers,
          "reference",
          this.search,
          page,
          itemsPerPage,
          axiosSource.token,
          sort
        ).then(response => {
          this.itemsCountStock = response.data.count;
          this.stocks = response.data.results;
          this.stock_loading = false;
        });
      }, 500);
    },
    formatDate(date) {
      const stringDate = date.toLocaleString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "numeric"
      });
      let stringHour = date.toLocaleString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit"
      });
      stringHour = stringHour.replace(":", "h");
      const returnDate = stringDate + " à " + stringHour;
      return returnDate;
    },
    changeItemStatus(item) {
      item.status = !item.status;
    },
    changeStatus(row) {
      this.loading_save = true;

      const fields = this.isHistory
        ? Object.keys(this.headersHistory[0])
        : Object.keys(this.headersStock[0]);

      row = {
        ...row,
        commandee_le: row.commandee_le.getTime()
      };

      DatasetService.update(
        this,
        Constant.datasetHistoriqueCommandes.id,
        row,
        fields,
        false
      ).then(response => {
        this.loading_save = false;
        setTimeout(() => {
          this.hasBeenModified = false;
        }, 1500);
      });
    },
    saveModifications() {
      if (this.operation_validated) return;

      this.loading_save = true;
      this.operation_validated = false;

      const fields = this.isHistory
        ? Object.keys(this.headersHistory[0])
        : Object.keys(this.headersStock[0]);

      this.tableData.forEach(item => {
        item = {
          ...item,
          commandee_le: item.commandee_le.getTime()
        };
        DatasetService.update(
          this,
          Constant.datasetHistoriqueCommandes.id,
          item,
          fields,
          false
        ).then(response => {
          this.loading_save = false;
          this.operation_validated = true;
          setTimeout(() => {
            this.operation_validated = false;
            this.hasBeenModified = false;
          }, 1500);
        });
      });
    },
    clearTables() {
      this.history = [];
      this.itemsCountHistory = 0;
      this.stocks = [];
      this.itemsCountStock = 0;
    }
  }
};
</script>

<style scoped>
.invisible-chip:hover::before {
  background: transparent !important;
  opacity: 1 !important;
}
</style>
