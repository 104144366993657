<template>
  <div>
    <v-row align="center" justify="end">
      <export-csv
        class="mr-3 mb-0"
        :filename="dataset_alertes_id"
        :dataset-id="dataset_alertes_id"
      />
    </v-row>
    <v-row class="mb-4">
      <v-col cols="12" lg="4" class="my-n4" align-self="center">
        <v-text-field
          v-model="searchEmail"
          label="Recherche par mail"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="my-n4" align-self="center">
        <v-text-field
          v-model="searchClient"
          label="Recherche par client"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
      <v-col cols="12" lg="4" class="my-n4" align-self="center">
        <v-text-field
          v-model="searchAdresse"
          label="Recherche par adresse"
          append-icon="mdi-magnify"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-card class="elevation-0 rounded-xl">
      <v-card-text class="d-flex justify-center">
        <hmd-table
          ref="tableAlertes"
          class="elevation-0"
          card
          paginate
          :recherche="{
            email_rejeté: searchEmail,
            client: searchClient,
            adresse: searchAdresse
          }"
          search-through-generic-filter
          :search-column="['email_rejeté', 'client', 'adresse']"
          :dataset="dataset_alertes_id"
          :conditional-format="[
            {
              cols: ['fiche'],
              text: 'Fiche',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['lien'],
              text: 'Lien',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['intégré_le'],
              text: 'Intégré le',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['email_rejeté'],
              text: 'Email rejeté',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['nv_email'],
              text: 'Nouvel Email',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['matériel_prévu'],
              text: 'Matériel Prévu',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['client'],
              text: 'Client',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['contact'],
              text: 'Contact',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['adresse'],
              text: 'Adresse',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['cp'],
              text: 'Code Postal',
              condition: v => v > 40,
              style: {}
            },
            {
              cols: ['ville'],
              text: 'Ville',
              condition: v => v > 40,
              style: {}
            }
          ]"
          :ignored-columns="['primary_key']"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ExportCsvButton from "@/components/hmd/ExportCsvButton";
import Table from "@/components/bnc/dashboard/Table";
import Constant from "@/constant";

export default {
  name: "EventExpandPanel",
  components: {
    "hmd-table": Table,
    "export-csv": ExportCsvButton
  },
  data: function() {
    return {
      loading: false,
      panel: [0],
      events: [],
      searchEmail: "",
      searchClient: "",
      searchAdresse: "",
      dataset_alertes_id: Constant.datasetAlertesKo.id
    };
  },
  watch: {
    search() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        // préparation de l'implémentation de la recherche sur le tableau des alertes
        // le champ sensé être recherché est simplement encore inconnu
      }, 500);
    }
  }
};
</script>

<style scoped></style>
