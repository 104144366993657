<template>
  <v-dialog v-model="dialog" max-width="600px">
    <template v-slot:activator="{ on }">
      <v-btn icon v-on="on" :disabled="item.en_stock <= 0" @click="open">
        <v-icon> mdi-plus </v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-col cols="5">
          <span class="text-h6 primary--text">Commande</span>
        </v-col>
        <v-col cols="7" class="text-right">
          <v-btn icon class="primary--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text class="pt-1">
        <v-row>
          <v-col cols="12"> </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-text-field
              ref="product"
              v-model="item.reference"
              name="product"
              outlined
              disabled
            >
              <template v-slot:label>
                P/N <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-col cols="12" class="ma-0 py-0"></v-col>
            <v-text-field
              ref="last_30_days_ordered"
              v-model="item.trente_jours"
              name="last_30_days_ordered"
              outlined
              disabled
            >
              <template v-slot:label>
                Qté commandée sur les 30 derniers jours
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-col cols="12" class="ma-0 py-0"></v-col>
            <v-text-field
              ref="last_90_days_ordered"
              v-model="item.quatre_vingt_dix_jours"
              name="last_90_days_ordered"
              outlined
              disabled
              required
            >
              <template v-slot:label>
                Qté commandée sur les 90 derniers jours
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-col cols="12" class="ma-0 py-0"></v-col>
            <v-text-field
              ref="plurium_available"
              v-model="item.en_stock"
              name="plurium_available"
              outlined
              disabled
              required
            >
              <template v-slot:label>
                Qté disponible chez Plurium
                <span class="new_orange--text">*</span>
              </template></v-text-field
            >
            <v-col cols="12" class="ma-0 py-0"></v-col>
            <v-text-field
              ref="quantity_asked"
              v-model="quantity_asked"
              name="quantity_asked"
              outlined
              required
              autofocus
              type="number"
              :rules="[
                quantity_asked_rules.required,
                quantity_asked_rules.maxValue,
                quantity_asked_rules.minValue,
                quantity_asked_rules.notNull
              ]"
              v-on:keyup.enter="save"
            >
              <template v-slot:label>
                Qté demandée
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-col cols="12" class="text-center mt-n7">
          <v-btn
            block
            large
            color="new_orange"
            class="white--text"
            :disabled="!formValid"
            :loading="loading_save"
            @click="save"
          >
            Commander
          </v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Constant from "@/constant";
import QueryService from "@/services/QueryService";
import DatasetService from "@/services/DatasetService";

export default {
  props: {
    profile: Object,
    item: Object
  },
  data() {
    return {
      dialog: false,
      loading_save: false,
      formValid: false,
      quantity_asked: "",
      quantity_asked_rules: {
        required: value => !!value || "Champ obligatoire",
        maxValue: value =>
          value <= this.item.en_stock ||
          "Quantité demandée supérieure à la quantité disponible",
        minValue: value => value >= 0 || "La quantité demandée doit être positive",
        notNull: value => value > 0 || "La quantité demandée ne peut pas être 0"
      }
    };
  },
  computed: {
  },
  watch: {
    quantity_asked(value){
      this.formValid = !!value && this.$refs.quantity_asked.validate()
    }
  },
  methods: {
    open() {
      this.dialog = true;
    },
    reset() {
      this.quantity_asked = "";
      this.formValid = false
    },
    close() {
      this.dialog = false;
      this.loading_save = false;
      this.reset();
      this.$emit("closed");
    },
    save() {
      if (this.$refs.quantity_asked.validate()) {
        this.sendEmail();
      }
    },
    sendEmail() {
      const senderName = `${this.profile.first_name} ${this.profile.last_name}`;

      const sendObject = {
        commandee_le: new Date().valueOf(),
        produit: this.item.reference,
        quantite: this.quantity_asked,
        demandeur: senderName,
        statut: false
      };
      
      this.loading_save = true;

      DatasetService.create(
        this,
        Constant.datasetHistoriqueCommandes.id,
        sendObject,
        false
      ).then(response => {
        QueryService.sendEmail(
          this,
          {
            product: this.item.reference,
            plurium_available: this.item.en_stock,
            last_30_days: this.item.trente_jours,
            last_90_days: this.item.quatre_vingt_dix_jours,
            quantity_asked: this.quantity_asked,
            requester: senderName
          },
          "Nouvelle commande de " + senderName,
          "email_commandes"
        ).then(response => {
          this.$store.commit("success", "Commande envoyée avec succès");          
          this.close();
        }).finally(() => {
          this.loading_save = false;
        });
      });
    }
  }
};
</script>
