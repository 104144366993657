<template>
  <v-card elevation="0" rounded="xl">
    <v-card-title>
      <div class="text-subtitle-1 font-weight-bold">
        {{ title }}
      </div>
    </v-card-title>
    <v-card-subtitle>
      <div v-if="subtitle" class="text-subtitle-2">
        {{ subtitle }}
      </div>
    </v-card-subtitle>
    <v-expand-transition>
      <v-card-text
        v-show="!contentHidden"
        class="pa-0"
        :class="chartType === 'donut' ? 'py-8' : ''"
      >
        <hmd-chart
          :format-type="formatType || 'numbers'"
          :ignored-columns="ignoredColumns"
          :type="chartType || 'bar'"
          :x-axis="xAxis"
          :y-axis="yAxis"
          :group-by="groupBy"
          :main-column="mainColumn"
          :query-name="queryName"
          :parameters="parameters"
          :dataset="dataset"
          :dataset-filter="datasetFilter"
          :unsaved-query-data="unsavedQueryData"
          :stroke-curve="strokeCurve"
          :thin-columns="thinColumns"
          :bar-color="barColor"
          :step-size="stepSize"
        />
      </v-card-text>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Chart from "@/components/bnc/dashboard/Chart";
export default {
  components: {
    "hmd-chart": Chart
  },
  props: {
    ignoredColumns: {
      type: Array,
      default: () => []
    },
    chartType: {
      type: String,
      default: null
    },
    xAxis: {
      type: String,
      default: null
    },
    yAxis: {
      type: String,
      default: null
    },
    groupBy: {
      type: String,
      default: null
    },
    mainColumn: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    subtitle: {
      type: String,
      default: null
    },
    queryName: {
      type: String,
      default: null
    },
    formatType: {
      type: String,
      default: null
    },
    dataset: {
      type: String,
      default: null
    },
    datasetFilter: {
      type: Object,
      default: null
    },
    unsavedQueryData: {
      type: Object,
      default: () => {
        return { rows: [], columns: [] };
      }
    },
    parameters: {
      type: Array,
      default: () => []
    },
    strokeCurve: {
      type: String,
      default: "monotoneCubic"
    },
    thinColumns: {
      type: Boolean,
      default: false
    },
    barColor: {
      type: String,
      default: "primary"
    },
    stepSize: {
      type: Number,
      default: null
    }
  },
  data() {
    return {
      contentHidden: false
    };
  }
};
</script>

<style scoped>
.subtitle-1 {
  font-family: "Open Sans", sans-serif;
  color: #004480;
}
</style>
