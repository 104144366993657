var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"3","align-self":"center"}},[_c('v-text-field',{attrs:{"label":"Recherche par P/N","append-icon":"mdi-magnify"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('export-csv',{staticClass:"mt-8",attrs:{"filename":_vm.isHistory ? 'historique_commandes' : 'suivi_stocks',"dataset-id":_vm.isHistory ? _vm.dataset_historique_id : _vm.dataset_stocks_id}}),_c('v-dialog',{staticStyle:{"display":"flex"},attrs:{"max-width":"700px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',{staticClass:"rounded-xl",staticStyle:{"display":"flex","flex-direction":"column"}},[_c('v-card-title',[_c('v-col',{attrs:{"cols":"5"}},[_c('span',{staticClass:"text-h6 primary--text"},[_vm._v("Stocks CIRCET")])]),_c('v-col',{staticClass:"text-right",attrs:{"cols":"7"}},[_c('v-btn',{staticClass:"primary--text",attrs:{"icon":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-subtitle',{staticClass:"ml-3 mt-n8"},[_c('span',{staticClass:"text-subtitle-1"},[_vm._v(" sur la référence "),_c('span',{staticClass:"font-weight-medium"},[_vm._v(" "+_vm._s(_vm.dialogData?.part_number)+" ")])])]),(!_vm.actualLocationCodes.length)?_c('p',{staticStyle:{"padding":"2rem"}},[(_vm.dialogData?.stock_circet)?_c('span',[_vm._v(" Localisation des stocks indisponible. ")]):_c('span',[_vm._v(" Aucun détail sur le stock CIRCET. ")])]):_c('v-data-table',{attrs:{"items":_vm.actualLocationCodes,"headers":[
            { title: 'Emplacement', value: 'location' },
            { title: 'Quantité', value: 'quantity' }
          ]}})],1)],1)],1),_c('v-card',{staticClass:"rounded-lg pa-5",attrs:{"elevation":"0"}},[_c('v-data-table',{attrs:{"items":_vm.tableData,"headers":_vm.headers,"loading":_vm.isHistory ? _vm.history_loading : _vm.stock_loading,"options":_vm.options,"server-items-length":_vm.itemsCount,"footer-props":{
        itemsPerPageOptions: [10, 50, 100]
      }},on:{"update:options":function($event){_vm.options=$event},"click:row":_vm.openDialogForRow},scopedSlots:_vm._u([{key:"item.traite_plurium",fn:function({ item }){return [_c('v-chip',{attrs:{"color":item.traite_plurium
              ? 'tableChipValid px-10'
              : 'tableChipInvalid px-8'}},[_vm._v(" "+_vm._s(item.traite_plurium ? "Traité" : "En cours")+" ")])]}},{key:"item.commandee_le",fn:function({ item }){return [_vm._v(" "+_vm._s(_vm.formatDate(item.commandee_le))+" ")]}},{key:"item.button",fn:function({ item }){return [_c('place-order',{attrs:{"profile":_vm.profile,"item":item},on:{"closed":_vm.searchStocks}})]}}])})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }