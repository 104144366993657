<template>
  <div>
    <v-data-table
      :loading="is.loading"
      :headers="table.new_cols"
      :items="table.rows"
      :fixed-header="true"
      :search="searchInput"
      :height="fillHeight ? height : 'auto'"
      class="elevation-3 px-5"
    >
      <template v-slot:top>
        <v-toolbar flat color="white" height="90">
          <v-container fluid>
            <v-col cols="12">
              <v-row>
                <v-col cols="4" class="mt-8 ml-n9">
                  <v-text-field
                    v-model="searchInput"
                    prepend-inner-icon="mdi-magnify"
                    placeholder="Recherche..."
                  />
                </v-col>
                <v-col cols="8" class="mt-8 d-flex justify-end ">
                  <user-create-update-dialog
                    v-if="isSuperAdmin"
                    v-model="editedItem"
                    :authorized-groups="authorizedGroups"
                    @created="createUpdateDialogEnd"
                    @updated="createUpdateDialogEnd"
                    @closed="
                      editedIndex = -1;
                      editedItem = {};
                      get();
                    "
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-container>
        </v-toolbar>
      </template>
      <template v-slot:item.group="{ item }">
        {{ item.group ?? "Aucun" }}
      </template>
      <template v-slot:item.last_login="{ item }">
        {{ formatLastLogin(item.last_login) }}
      </template>
      <template v-slot:item.action="{ item }">
        <v-btn
          rounded
          fab
          color="primary"
          class="mr-3"
          text
          x-small
          @click="editItem(item)"
        >
          <v-icon small>
            mdi-pencil
          </v-icon>
        </v-btn>
        <v-btn
          rounded
          fab
          dark
          color="red"
          text
          x-small
          @click="openDeleteDialog(item)"
        >
          <v-icon small> mdi-delete </v-icon>
        </v-btn>
      </template>
    </v-data-table>
    <v-dialog v-model="deleteDialog" max-width="650px">
      <v-card class="rounded-xl pa-4">
        <v-card-title></v-card-title>
        <v-card-text class="pt-1">
          <v-row class="justify-center mb-1">
            <h4 class="black--text text-h6">
              Êtes-vous sûr de vouloir supprimer l'utilisateur ?
            </h4>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-row>
            <v-col cols="12">
              <v-btn
                class="mt-2 white--text text-button"
                color="new_orange"
                block
                x-large
                @click="deleteItem(itemToDelete)"
              >
                Supprimer
              </v-btn>
            </v-col>
            <v-col cols="12" class="mt-n2">
              <v-btn
                block
                x-large
                class="mb-4 text-button"
                @click="closeDeleteDialog"
              >
                Annuler
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import CompanyService from "@/services/CompanyService";
import CompanyGroupService from "@/services/CompanyGroupService";
import Constant from "@/constant";
import UserCreateUpdateDialog from "@/components/hmd/Users/UserCreateUpdateDialog";
import _ from "lodash";

export default {
  components: {
    UserCreateUpdateDialog
  },
  props: {
    fillHeight: {
      type: Boolean,
      default: false
    },
    authorizedGroups: {
      type: Array,
      default: () => []
    },
    refreshKey: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    height: 500,
    searchInput: "",
    table: {
      new_rows: [],
      rows: [],
      new_cols: [
        {
          text: "Prénom",
          value: "first_name",
          sort: false
        },
        {
          text: "Nom",
          value: "last_name",
          sort: false
        },
        {
          text: "Email",
          value: "email",
          sort: false
        },
        {
          text: "Groupe",
          value: "group",
          sort: false
        },
        {
          text: "Dernière connexion",
          value: "last_login",
          sort: false,
          filterable: false
        }
      ],
      cols: [
        {
          text: "Nom",
          value: "first_name",
          sort: false
        },
        {
          text: "Prénom",
          value: "last_name",
          sort: false
        },
        {
          text: "Groupe",
          value: "group",
          sort: false
        },
        {
          text: "Utilisateur",
          value: "username",
          sort: false
        },
        {
          text: "Email",
          value: "email",
          sort: false
        },
        {
          text: "Actions",
          value: "action",
          sort: false
        }
      ]
    },
    editedIndex: -1,
    editedItem: {},
    is: {
      loading: true
    },
    deleteDialog: false,
    itemToDelete: null,
    distinctGroups: [],
    group: "",
    admins: [...Constant.super_admin_groups()]
  }),

  computed: {
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
    isActionsDisabled() {
      return this.is.loading;
    },
    isSuperAdmin() {
      return this.admins.includes(this.group);
    }
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    refreshKey() {
      this.get();
    }
  },

  created() {
    this.group = this.$store.getters.group;

    if (this.admins.includes(this.group)) {
      this.table.new_cols.push({
        text: "Actions",
        value: "action",
        sort: false,
        filterable: false
      });
    }
  },

  mounted() {
    const $ = this;
    this.get();
    $.height = window.innerHeight - 300;
    window.addEventListener("resize", e => {
      $.height = window.innerHeight - 300;
    });
  },

  methods: {
    get() {
      this.is_loading = true;
      return CompanyService.list_user_except_plurium(this).then(r => {
        // Get distinct groups
        const groups = new Set();
        r.results.forEach(user => {
          user.groups.forEach(group => {
            groups.add(group);
          });
        });
        this.distinctGroups = Array.from(groups);

        // Translate groups ids to names

        const groupNames = {};
        const groupPromises = this.distinctGroups.map(group => {
          return CompanyGroupService.get_group_company(this, group).then(r => {
            groupNames[group] = r.name;
          });
        });

        Promise.all(groupPromises)
          .then(() => {
            // Change group ids to group names in users list
            r.results = r.results.map(u => {
              u.groups = [groupNames[u.groups[0]]];
              return u;
            });

            r.results = r.results.map(u => {
              u.company = Constant.company;
              u.group = Constant.groups().filter(g => {
                return u.groups.includes(g);
              })[0];
              return u;
            });
            this.table.rows = r.results.map(u => {
              return {
                ...u,
                // this is to ease sorting by formatting last_login to a timestamp
                last_login: new Date(u.last_login).valueOf()
              };
            });
          })
          .finally(() => {
            this.is.loading = false;
          });
      });
    },

    editItem(item) {
      this.editedIndex = this.table.rows.indexOf(item);
      this.editedItem = _.cloneDeep(item);
    },

    deleteItem(item) {
      const index = this.table.rows.indexOf(item);
      CompanyService.User.delete(this, item.id)
        .then(() => {
          this.closeDeleteDialog();
          this.table.rows.splice(index, 1);
        })
        .catch(() => {
          this.$store.commit("push", {
            msg: "Vous n'avez pas le droit de supprimer cet utilisateur",
            color: "error"
          });
        });
    },

    closeDeleteDialog() {
      this.deleteDialog = false;
      this.$emit("refresh");
      this.get();
    },

    openDeleteDialog(item) {
      this.itemToDelete = item;
      this.deleteDialog = true;
    },

    formatLastLogin(lastLogin) {
      if (!lastLogin) return "Jamais connecté";

      const dateLastLogin = new Date(lastLogin);

      const formattedDate = dateLastLogin.toLocaleDateString("fr-FR", {
        day: "2-digit",
        month: "2-digit",
        year: "2-digit"
      });
      const formattedTime = dateLastLogin.toLocaleTimeString("fr-FR", {
        hour: "2-digit",
        minute: "2-digit"
      });

      return `${formattedDate} à ${formattedTime}`;
    },

    createUpdateDialogEnd() {
      this.$emit("refresh");
      this.get();
    }
  }
};
</script>

<style scoped></style>
