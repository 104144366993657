import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#0055A4",
        secondary: "#1480CF",
        tertiary: "#495057",
        accent: "#eb4952",
        error: "#f55a4e",
        info: "#0E264A",
        success: "#5cb860",
        warning: "#ffa21a",
        new_blue: "#009DCC",
        new_orange: "#EA5B0F",
        black_text: "#001737",
        background: "#f3f3f3",
        filter_btn: "#cdd6dc",
        subtitle: "#495463",
        user_square: "#1763aa",
        tableChipValid: "#98ed95",
        tableChipInvalid: "#FBDECF",
        black: "#000000",
        nav_links: "#4080bb"
      }
    }
  },
  icons: {
    iconfont: "mdi"
  }
});
