<template>
  <v-container>
    <!-- Compteur -->
    <v-row align="center" class="pr-3">
      <h4 class="black_text--text ml-3">
        {{ KPITimeSelected }}
      </h4>
      <v-spacer></v-spacer>
      <!-- <filter-dates
        custom-title="Filtrer les KPIs"
        :start-date="startDate"
        :end-date="endDate"
        @filtered="filterDates"
      ></filter-dates> -->
      <!-- INFO : all logic is already implemented for filter date, if needed, simply uncomment this code -->
    </v-row>
    <v-row class="py-2">
      <v-col
        v-for="counter in kpi_counters_row_1"
        :key="counter.kpiId"
        class="xs pb-4"
        cols="12"
        :md="12 / kpi_counters_row_1.length"
      >
        <hmd-counter
          class="mb-0"
          :label="counter.label"
          :value="counter.value"
          :value-type="counter.valueType"
          :query-name="counter.kpiId"
          :last-row="counter.lastRow"
          :is-blue="counter.isBlue"
          :rate="counter.rate"
          :date-filter-start="startDate"
          :date-filter-end="endDate"
          sm="6"
          md="4"
        />
      </v-col>
    </v-row>
    <v-divider class="mt-6 mb-7"></v-divider>
    <v-row class="py-2">
      <v-col class="xs" cols="12" sm="12" md="12" lg="8">
        <hmd-table-chart
          class="actualiser py-7"
          title="Total des CPEs reçus"
          format-type="numbers"
          query-name="cpe_all"
          :parameters="queryParametersSixMonths"
          x-axis="date"
          chart-type="area"
          stroke-curve="straight"
          main-column="CPEs reçus"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="12" lg="4">
        <hmd-table-chart
          class="actualiser donut-chart"
          title="Destination des CPEs reçus"
          subtitle="Indicateurs des 12 derniers mois à J-1"
          format-type="numbers"
          query-name="repartition"
          :parameters="queryParameters"
          x-axis="etat_sortie"
          y-axis="Total"
          chart-type="donut"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="12" lg="6">
        <hmd-table-chart
          class="actualiser py-3"
          format-type="currency"
          title="Total des recettes rachat de CPEs"
          query-name="total_recettes_rachat_cpe"
          :parameters="queryParametersSixMonths"
          x-axis="date"
          y-axis="montant"
          :ignored-columns="['primary_key']"
          chart-type="bar"
          bar-color="blue"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="12" lg="6">
        <hmd-table-chart
          class="actualiser cout-rse pl-2"
          title="Coût RSE"
          format-type="numbers"
          :query-name="queryCoutRse"
          :ignored-columns="['primary_key']"
          x-axis="date"
          chart-type="area"
          stroke-curve="smooth"
        />
      </v-col>

      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          format-type="currency"
          title="Montant des ventes à BTE Market"
          query-name="ventes_bte_montant"
          :parameters="queryParametersSixMonths"
          x-axis="date"
          y-axis="montant"
          :ignored-columns="['primary_key']"
          chart-type="bar"
          :thin-columns="true"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="6" lg="6">
        <hmd-table-chart
          class="actualiser"
          format-type="numbers"
          title="Volume des ventes à BTE Market"
          query-name="ventes_bte_unites"
          :parameters="queryParametersSixMonths"
          x-axis="date"
          y-axis="total"
          chart-type="bar"
          :thin-columns="true"
          bar-color="secondary"
        />
      </v-col>
      <v-col class="xs" cols="12" sm="12" md="12">
        <hmd-table-chart
          class="actualiser"
          format-type="currency"
          title="Total des coûts pour la maintenance"
          query-name="depense"
          :parameters="queryParametersSixMonths"
          x-axis="date"
          y-axis="total"
          chart-type="bar"
          bar-color="yellow"
        />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Counter from "@/components/bnc/dashboard/Counter";
import TableChart from "@/components/bnc/dashboard/TableChart";
import FilterDates from "@/components/hmd/FilterDates.vue";
import Constant from "@/constant";

export default {
  components: {
    "hmd-counter": Counter,
    "hmd-table-chart": TableChart,
    "filter-dates": FilterDates
  },
  data() {
    return {
      period: "12 Month",
      kpi_ids: {
        amount_cpes_purchased_last_month:
          Constant.query_amount_cpes_purchased_last_month.name,
        depense_total_last_month: Constant.query_depense_total_last_month.name,
        materiel_recu_last_month: Constant.query_materiel_recu_last_month.name
      },
      kpi_counters_row_1: [
        {
          label: "Nombre de matériels reçus",
          value: "Nombre de matériels reçus",
          valueType: "",
          kpiId: "",
          lastRow: false,
          isBlue: true
        },
        {
          label: "Montant des CPEs rachetés",
          value: "Montant des CPEs rachetés",
          valueType: "currency",
          kpiId: "",
          lastRow: false,
          isBlue: true
        },
        {
          label: "Coût de la maintenance",
          value: "Coût de la prestation",
          valueType: "currency",
          kpiId: "",
          lastRow: false,
          isBlue: true
        }
      ],
      startDate: new Date(),
      endDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      timeSelected: "",
      queryCoutRse: Constant.query_cout_rse.name
    };
  },
  computed: {
    queryParameters() {
      const parameters = [];
      parameters.push({
        name: "interval",
        type: "Text",
        value: this.period
      });
      return parameters;
    },
    queryParametersSixMonths() {
      const parameters = [];
      parameters.push({
        name: "interval",
        type: "Text",
        value: "6 Month"
      });
      return parameters;
    },
    KPITimeSelected() {
      switch (this.timeSelected) {
        case "1_month":
          return "Indicateurs du mois en cours à J-1";
        case "3_month":
          return "Indicateurs des 3 derniers mois à J-1";
        case "6_month":
          return "Indicateurs des 6 derniers mois à J-1";
        case "12_month":
          return "Indicateurs des 12 derniers mois à J-1";
        case "custom":
          return (
            "Indicateurs du " +
            this.endDate.toLocaleDateString("fr-FR") +
            " au " +
            this.startDate.toLocaleDateString("fr-FR")
          );
        default:
          return "Indicateurs du mois en cours à J-1";
      }
    }
  },
  mounted() {
    this.kpi_counters_row_1[0].kpiId = this.kpi_ids.materiel_recu_last_month;
    this.kpi_counters_row_1[1].kpiId = this.kpi_ids.amount_cpes_purchased_last_month;
    this.kpi_counters_row_1[2].kpiId = this.kpi_ids.depense_total_last_month;
  },
  methods: {
    filterDates(eventObject) {
      this.startDate = eventObject.startDate;
      this.endDate = eventObject.endDate;
      this.timeSelected = eventObject.timeSelected;
    }
  }
};
</script>

<style scoped>
.donut-chart {
  max-height: 381px !important;
}

.cout-rse {
  min-height: 349px !important;
}
</style>
