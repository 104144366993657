<template>
  <div>
    <v-row class="my-4">
      <v-col cols="3">
        <v-text-field
          v-model="search"
          class="my-n6 ml-3"
          label="Recherche"
          append-icon="mdi-magnify"
        ></v-text-field>
      </v-col>
      <v-spacer />
      <v-col cols="5" class="pr-10">
        <v-row justify="end">
          <filter-dates
            :start-date="startDate"
            :end-date="endDate"
            @filtered="filterDates"
          ></filter-dates>
          <new-request
            v-if="isSuperAdmin"
            @fileSelected="uploadFile"
          ></new-request>
        </v-row>
      </v-col>
    </v-row>
    <v-card elevation="0" class="pa-8 pt-2 mx-1 rounded-xl">
      <v-card-title>
        <span> Fichiers à traiter {{ formatDateRange }} </span>
      </v-card-title>
      <v-data-table
        :loading="loading"
        :items="tableData"
        :headers="headers"
        :search="search"
        :options.sync="options"
        :server-items-length="itemsCount"
        :footer-props="{
          itemsPerPageOptions: [10, 50, 100]
        }"
      >
        <template v-slot:item.date="{ item }">
          {{ formatDate(item.date) }}
        </template>
        <template v-slot:item.uploaded_by="{ item }">
          {{ item.uploaded_by }}
        </template>
        <template v-slot:item.actions="{ item }">
          <v-row>
            <v-btn class="primary--text" icon @click="downloadFile(item)">
              <v-icon>mdi-arrow-collapse-down</v-icon>
            </v-btn>
          </v-row>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
import FilterDates from "@/components/hmd/FilterDates.vue";
import NewRequest from "@/components/hmd/Treatment/NewRequest.vue";
import Constant from "@/constant";
import FileService from "@/services/FileService";

export default {
  name: "Treatment",
  components: {
    "filter-dates": FilterDates,
    "new-request": NewRequest
  },
  data() {
    return {
      loading: true,
      search: "",
      tableData: [],
      headers: [
        { text: "Nom du fichier", value: "original_name", align: "center" },
        {
          text: "Dernière mise à jour",
          value: "uploaded_at",
          align: "center",
          filterable: false
        },
        {
          text: "Créé par",
          value: "uploaded_by",
          align: "center",
          filterable: false
        }
      ],
      startDate: new Date(new Date().setDate(new Date().getDate() + 1)),
      endDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      timeSelected: null,
      options: {
        page: 1,
        itemsPerPage: 50
      },
      itemsCount: 0,
      debounceTimer: null
    };
  },
  computed: {
    isSuperAdmin() {
      const userGroup = this.$store.getters.group;
      return Constant.admin_groups().includes(userGroup);
    },
    formatDateRange() {
      switch (this.timeSelected) {
        case "1_month":
          return "durant le dernier mois";
        case "3_month":
          return "durant les 3 derniers mois";
        case "6_month":
          return "durant les 6 derniers mois";
        case "12_month":
          return "durant l'année passée";
        case "custom":
          return (
            "du " +
            this.endDate.toLocaleDateString("fr-FR") +
            " au " +
            this.startDate.toLocaleDateString("fr-FR")
          );
        default:
          return "durant le dernier mois";
      }
    }
  },
  watch: {
    options: {
      handler() {
        if (this.search !== "") {
          this.recherche();
        } else {
          this.get();
        }
      },
      deep: true
    },
    search() {
      this.recherche();
    }
  },
  created() {
    if (this.isSuperAdmin) {
      this.headers.push({
        text: "",
        value: "actions",
        sortable: false,
        align: "center"
      });
    }
  },
  methods: {
    filterDates(eventObject) {
      this.startDate = eventObject.startDate;
      this.endDate = eventObject.endDate;
      this.timeSelected = eventObject.timeSelected;
      this.get();
    },
    formatDate(date) {
      const options = { timeZone: "Europe/Paris" };
      return new Date(date).toLocaleString("fr-FR", options).replace(/T/, " ");
    },
    getDifferenceInDays(date1, date2) {
      const oneDay = 24 * 60 * 60 * 1000;
      const diffInTime = date2.getTime() - date1.getTime();
      return Math.round(diffInTime / oneDay);
    },
    get() {
      const { page, itemsPerPage } = this.options;

      const startDate = this.endDate.toISOString().split("T")[0];
      const endDate = this.startDate.toISOString().split("T")[0];

      this.loading = true;
      FileService.getAll(this, startDate, endDate, page, itemsPerPage)
        .then(response => {
          this.itemsCount = response.count;
          this.tableData = response.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    recherche() {
      clearTimeout(this.debounceTimer);
      this.debounceTimer = setTimeout(() => {
        const { page, itemsPerPage } = this.options;

        if (this.search === "") {
          this.get();
        } else {
          const startDate = this.endDate.toISOString().split("T")[0];
          const endDate = this.startDate.toISOString().split("T")[0];

          this.loading = true;
          FileService.searchAll(
            this,
            "original_name",
            this.search,
            page,
            itemsPerPage,
            startDate,
            endDate
          )
            .then(response => {
              this.itemsCount = response.count;
              this.tableData = response.results;
            })
            .finally(() => {
              this.loading = false;
            });
        }
      }, 500);
    },
    uploadFile(file) {
      FileService.upload(this, file, Constant.project).then(() => {
        this.$store.commit("success", "Fichier uploadé avec succès");
        this.get();
      });
    },
    downloadFile(file) {
      FileService.download(this, file).then(link => {
        link.download = file.original_name;
        link.click();
      });
    }
  }
};
</script>
