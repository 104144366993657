<template>
  <v-container class="fill-height" fluid>
    <v-row justify="center" align="center">
      <v-col class="align" cols="12"><h1>Bouygues Telecom</h1></v-col>
      <v-col class="align" cols="12"
        ><h2 class="red--text">
          Erreur: Invitation invalide ou expirée
        </h2></v-col
      >
      <v-col class="align" cols="12"
        >Veuillez contacter la personne qui vous a invité
      </v-col>
      <v-col class="align" cols="12"
        >Si vous avez un compte vous pouvez rejoindre la page de connexion en
        cliquant sur ce bouton
      </v-col>
      <v-btn
        label="Connexion"
        color="primary"
        class="mt-4"
        @click="$router.replace('/signin')"
        >Page de connexion
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
export default {};
</script>

<style scoped></style>
