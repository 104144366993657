<template>
  <v-container fluid>
    <v-row class="justify-center">
      <v-col cols="3">
        <v-text-field
          v-model="searchInput"
          append-icon="mdi-magnify"
          placeholder="Rechercher"
        />
      </v-col>
      <v-spacer></v-spacer>
      <v-col cols="1" align-self="center">
        <filter-dates
          :start-date="startDate"
          :end-date="endDate"
          @filtered="filterDates"
        />
      </v-col>
    </v-row>
    <v-row>
      <v-card elevation="0" class="rounded-xl">
        <v-card-text class="mt-13 mb-9">
          <p class="text-center font-weight-bold black--text mb-0 text-h4">
            {{ tableData.length }}
          </p>
          <p class="new_blue--text">
            Flux {{ outgoing ? "sortants" : "entrants" }} prestataire
          </p>
        </v-card-text>
      </v-card>
    </v-row>
    <v-row class="mx-n6 mt-10">
      <v-col cols="12">
        <v-card elevation="0" class="rounded-xl">
          <v-data-table
            :items="tableData"
            :headers="tableHeaders"
            :search="searchValue"
          >
            <template v-slot:item.date_expedition="{ item }">
              Le {{ new Date(item.date_expedition).toLocaleDateString() }} à
              {{ new Date(item.date_expedition).toLocaleTimeString() }}
            </template>
            <template v-slot:item.date_reception="{ item }">
              Le {{ new Date(item.date_expedition).toLocaleDateString() }} à
              {{ new Date(item.date_expedition).toLocaleTimeString() }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import FilterDates from "@/components/hmd/FilterDates.vue";
import QueryService from "@/services/QueryService.js";
import Constant from "@/constant.js";

export default {
  components: {
    "filter-dates": FilterDates
  },
  props: {
    outgoing: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      tableData: [],
      tableHeaders: [
        {
          text: "Dates d'expédition",
          value: "date_expedition",
          align: "center",
          filterable: false
        },
        {
          text: "Numéro de B/L",
          value: "numero_bl",
          align: "center"
        },
        {
          text: "Nombre de produits",
          value: "nombre_produits",
          align: "center"
        },
        {
          text: "Date de réception",
          value: "date_reception",
          align: "center",
          filterable: false
        }
      ],
      searchInput: "",
      startDate: new Date(),
      endDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      timeSelected: ""
    };
  },
  computed: {
    dateTimeParams() {
      return {
        startDate: this.startDate,
        endDate: this.endDate,
        timeSelected: this.timeSelected
      };
    },
    searchValue() {
      return this.searchInput;
    }
  },
  watch: {
    outgoing: function() {
      this.populate();
    },
    dateTimeParams: {
      handler() {
        this.populate();
      },
      deep: true
    }
  },
  created() {
    this.populate();
  },
  methods: {
    populate() {
      const parameters = [];

      if (this.timeSelected) {
        parameters.push({
          name: "dates",
          type: "text",
          value: `${this.endDate.valueOf()}|${this.startDate.valueOf()}`
        });
      }

      if (this.outgoing) {
        QueryService.run(
          this,
          Constant.query_flux_sortant.name,
          parameters
        ).then(response => {
          this.tableData = response.data.rows;
        });
      } else {
        QueryService.run(
          this,
          Constant.query_flux_entrant.name,
          parameters
        ).then(response => {
          this.tableData = response.data.rows;
        });
      }
    },

    filterDates(eventObject) {
      this.startDate = eventObject.startDate;
      this.endDate = eventObject.endDate;
      this.timeSelected = eventObject.timeSelected;
    }
  }
};
</script>
