/**
 * File containing all unsaved query,
 *
 * these query need custom parameter from user input that's why there are 'unsaved'
 */

import QueryService from "@/services/QueryService";
import Constant from "@/constant";

export default {
  generateDecompte($, dateDebut, dateFin, query = this) {
    const sqlMaterielsRecus = `SELECT count(client) as "Nombre de matériels reçus dans les fiches clôturées" \
        FROM ${Constant.datasetEnlevement.name} as e \
        inner join ${Constant.datasetLigEnlevement.name} as el \
        ON (e.FICHE = el.FICHE) \
        WHERE TO_DATE(replace(e.date_integre::TEXT, '.0', ''), 'YYYYMMDD') BETWEEN ${dateDebut} AND ${dateFin} \
        AND el.reference_recu != 'ANNULE' \
        AND e.date_recu::TEXT > '';`;

    const sqlMaterielPrevu = `select count(client) as "Nombre de matériels prévus dans les fiches clôturées" \
        FROM ${Constant.datasetEnlevement.name} as e \
        inner join ${Constant.datasetLigEnlevement.name} as el \
        ON (e.FICHE=el.FICHE) \
        WHERE TO_DATE(replace(e.date_integre::TEXT, '.0', ''), 'YYYYMMDD') BETWEEN ${dateDebut} AND ${dateFin} \
        AND e.date_recu::TEXT > '';`;

    const sqlFichesCloturees = `SELECT count(client) as "Nombre de fiches dont au moins un matériel a été reçu" \
          from ${Constant.datasetEnlevement.name} \
          WHERE date_recu::TEXT > '' \
          AND TO_DATE(replace(date_integre::TEXT, '.0', ''), 'YYYYMMDD') BETWEEN ${dateDebut} AND ${dateFin} \
          ;`;

    const sqlMaterielsAnnules = `SELECT count(client) as "Nombre de matériels annulés dans les fiches clôturées"\
        FROM ${Constant.datasetEnlevement.name} as e INNER JOIN ${Constant.datasetLigEnlevement.name} as el on (e.FICHE=el.FICHE) \
        WHERE TO_DATE(replace(date_integre::TEXT, '.0', ''), 'YYYYMMDD') BETWEEN ${dateDebut} AND ${dateFin} \
          AND el.date_recu::TEXT > '' AND el.reference_recu = 'ANNULE';`;

    const sqlMaterielsFichesIntegrees = `SELECT count(e.client) as "Nombres de matériels dans les fiches" \
        FROM \
          ${Constant.datasetEnlevement.name} as e inner join ${Constant.datasetLigEnlevement.name} as el on (e.FICHE=el.FICHE) \
        WHERE \
          TO_DATE(replace(e.date_integre::TEXT, '.0', ''), 'YYYYMMDD') BETWEEN ${dateDebut} AND ${dateFin} \
        ;`;

    const sqlFichesIntegrees = `SELECT count(client) as "Nombre de fiches" \
        FROM ${Constant.datasetEnlevement.name} \
        WHERE TO_DATE(replace(date_integre::TEXT, '.0', ''), 'YYYYMMDD') BETWEEN ${dateDebut} AND ${dateFin} \
        ;`;

    const queryes = [
      QueryService.unsavedQuery($, sqlMaterielsRecus, Constant.project),
      QueryService.unsavedQuery($, sqlMaterielPrevu, Constant.project),
      QueryService.unsavedQuery($, sqlFichesCloturees, Constant.project),
      QueryService.unsavedQuery($, sqlMaterielsAnnules, Constant.project),
      QueryService.unsavedQuery(
        $,
        sqlMaterielsFichesIntegrees,
        Constant.project
      ),
      QueryService.unsavedQuery($, sqlFichesIntegrees, Constant.project)
    ];

    return Promise.all(queryes).then(result => {
      const rows = [{}];
      const columns = [];
      for (const query in result) {
        for (const column in result[query].data.columns) {
          columns.push(result[query].data.columns[column]);
        }
        for (const row in result[query].data.rows) {
          for (let key in Object.keys(result[query].data.rows[row])) {
            key = Object.keys(result[query].data.rows[row])[key];
            rows[0][key] = result[query].data.rows[row][key];
          }
        }
      }

      const data = {
        rows: rows,
        columns: columns
      };
      return data;
    });
  },
  repartition($, dateDebut, dateFin, query = this) {
    const sql = `SELECT count(*) as "Total", "etat-sortie"\
            FROM ${Constant.datasetBte.name} \
            WHERE date_recu BETWEEN ${dateDebut} AND ${dateFin}\
            AND "etat-sortie" != ''\
            AND "etat-sortie" != 'Envoi en réparation'\
            GROUP BY "etat-sortie"\
            ;`;

    return QueryService.unsavedQuery($, sql, Constant.project).then(result => {
      return result;
    });
  },
  recu($, dateDebut, dateFin, query = this) {
    const sql = `SELECT \
            to_char(date_trunc('month', date(date_recu)), 'mm-YYYY') as "Mois",\
            count(*) as "Total reçu"\
            FROM ${Constant.datasetBte.name}\
            WHERE date_recu BETWEEN ${dateDebut} AND ${dateFin}\
            GROUP BY date_trunc('month', date(date_recu))\
            ORDER BY date_trunc('month', date(date_recu)) ASC\
            ;`;

    return QueryService.unsavedQuery($, sql, Constant.project).then(result => {
      return result;
    });
  },
  depenses($, dateDebut, dateFin, query = this) {
    const sql = `SELECT \
            to_char(date_trunc('month', date("mois/année")), 'mm-YYYY') as "Mois",\
            total,\
            dont_transport as "transport"\
            FROM ${Constant.datasetFacturationBte.name}\
            WHERE "mois/année" BETWEEN ${dateDebut} AND ${dateFin}\
            ;`;

    return QueryService.unsavedQuery($, sql, Constant.project).then(result => {
      return result;
    });
  },
  recettes($, dateDebut, dateFin, query = this) {
    const sql = `SELECT to_char(date_trunc('month', date(clôturé_le)), 'mm-YYYY') as "Mois",\
            sum(ht_commandé) as "Total"\
            FROM ${Constant.datasetRecette.name}\
            WHERE clôturé_le BETWEEN ${dateDebut} AND ${dateFin}\
            GROUP BY date_trunc('month', date(clôturé_le))\
            ORDER BY date_trunc('month', date(clôturé_le)) ASC\
            ;`;

    return QueryService.unsavedQuery($, sql, Constant.project).then(result => {
      return result;
    });
  },
  getQuery(queryName) {
    switch (queryName) {
      case "generateFacture":
        return this.generateFacture;
      case "generateDecompte":
        return this.generateDecompte;
      case "repartition":
        return this.repartition;
      case "recu":
        return this.recu;
      case "depenses":
        return this.depenses;
      case "recettes":
        return this.recettes;
      default:
        return new Error("Method do not exist or is not implmented");
    }
  }
};
