<template>
  <v-dialog v-model="dialog" max-width="500">
    <template v-slot:activator="{ on }">
      <v-btn small plain class="filter_btn" v-on="on">
        {{ customTitle ?? "Filtrer" }}
        <v-icon class="primary--text">mdi-filter-outline</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <v-col cols="10">
          <span class="text-h6 primary--text mb-0">
            {{ customTitle ?? "Filtre" }}
          </span>
          <br />
          <span class="text-caption subtitle--text mt-0"
            >Par période ou entre 2 dates</span
          >
        </v-col>
        <v-col cols="2" class="text-right">
          <v-btn icon class="primary--text" @click="close">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-select
          v-model="selected"
          :items="options"
          :hint="selectHint"
          persistent-hint
          item-text="name"
          item-value="value"
          label="Période"
          outlined
          @change="setNewDates()"
        ></v-select>
      </v-card-text>
      <v-card-text v-if="selected === 'custom'">
        <v-row>
          <v-col cols="6">
            <v-menu v-model="startDateMenu" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="startDatePicker"
                  prepend-icon="mdi-calendar"
                  readonly
                  hint="à 00:00"
                  persistent-hint
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                ref="startDatePicker"
                v-model="startDatePicker"
                no-title
                scrollable
                label="Date de fin"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startDateMenu = false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="6">
            <v-menu v-model="endDateMenu" :close-on-content-click="false">
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="endDatePicker"
                  prepend-icon="mdi-calendar"
                  readonly
                  hint="à 00:00"
                  persistent-hint
                  v-on="on"
                >
                </v-text-field>
              </template>
              <v-date-picker
                ref="endDatePicker"
                v-model="endDatePicker"
                no-title
                scrollable
                label="Date de fin"
              >
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="endDateMenu = false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions v-if="selected" class="d-block">
        <v-col cols="12" class="text-center">
          <v-btn large block color="primary" @click="validate">Filtrer</v-btn>
        </v-col>
        <v-col cols="12">
          <v-btn block text @click="close">Annuler</v-btn>
        </v-col>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    startDate: {
      type: Date,
      required: true
    },
    endDate: {
      type: Date,
      required: true
    },
    customTitle: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      dialog: false,
      options: [
        { name: "30 Jours", value: "1_month" },
        { name: "3 Mois", value: "3_month" },
        { name: "6 Mois", value: "6_month" },
        { name: "12 Mois", value: "12_month" },
        { name: "Personnalisé", value: "custom" }
      ],
      selected: null,
      startDateMenu: false,
      endDateMenu: false,
      startDatePicker: new Date().toLocaleDateString("fr-CA"), // Don't change format for both these 2 dates
      endDatePicker: new Date().toLocaleDateString("fr-CA"), // they are NOT displayed, only used during handling
      startDateOutput: null,
      endDateOutput: null,
      startDateHint: "",
      endDateHint: ""
    };
  },
  computed: {
    selectHint() {
      if (this.selected) {
        return this.selected === "custom"
          ? ""
          : `${this.startDateHint} 00:00 - ${this.endDateHint} 23:59`;
      }
      return "";
    }
  },
  methods: {
    validate() {
      this.setNewDates();
      this.$emit("filtered", {
        startDate: this.endDateOutput,
        endDate: this.startDateOutput,
        timeSelected: this.selected
      });
      this.close();
    },
    close() {
      this.dialog = false;
    },
    setNewDates() {
      let timeSelected;

      switch (this.selected) {
        case "1_month":
          timeSelected = new Date(
            new Date().setMonth(new Date().getMonth() - 1)
          );
          break;
        case "3_month":
          timeSelected = new Date(
            new Date().setMonth(new Date().getMonth() - 3)
          );
          break;
        case "6_month":
          timeSelected = new Date(
            new Date().setMonth(new Date().getMonth() - 6)
          );
          break;
        case "12_month":
          timeSelected = new Date(
            new Date().setFullYear(new Date().getFullYear() - 1)
          );
          break;
        case "custom":
          timeSelected = null;
          break;
        default:
          timeSelected = null;
      }

      if (timeSelected !== null) {
        this.startDateOutput = timeSelected;
        this.endDateOutput = new Date(Date.now());
      } else {
        this.startDateOutput = new Date(this.startDatePicker);
        this.endDateOutput = new Date(this.endDatePicker);
      }

      this.endDateOutput = new Date(
        this.endDateOutput.setDate(this.endDateOutput.getDate() + 1)
      );

      this.startDateHint = this.startDateOutput.toLocaleDateString("fr-FR");
      this.endDateHint = this.endDateOutput.toLocaleDateString("fr-FR");
    }
  }
};
</script>
