import Numeral from "numeral";

Numeral.register("locale", "fr", {
  delimiters: {
    thousands: " ",
    decimal: ","
  },
  abbreviations: {
    thousand: "k",
    million: "m",
    billion: "b",
    trillion: "t"
  },
  ordinal: function() {
    return "";
  },
  currency: {
    symbol: "€"
  }
});

Numeral.locale("fr");

export default Numeral;
