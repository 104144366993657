<template>
  <v-app>
    <row-stat-display
      :title="`${title}`"
      :name-first-column="'Libelle'"
      :name-second-column="'Valeur'"
      :datas="datas"
    />
  </v-app>
</template>

<script>
import RowStatDisplay from "@/components/hmd/RowStatDisplay";
import Query from "@/query";
import common from "@/utils/common";

export default {
  components: {
    RowStatDisplay
  },
  data() {
    return {
      datas: {},
      title: "",
      query: this.$route.params.query
    };
  },
  mounted() {
    // Récupération des arguemnts + title + nom de la query
    const dateDebut = `Date('${this.$route.params.dateDebut}')`;
    const dateFin = `Date('${this.$route.params.dateFin}')`;
    this.title = `${
      this.$route.params.title
    } du ${common.formatHumanReadableDate(
      this.$route.params.dateDebut
    )} au ${common.formatHumanReadableDate(this.$route.params.dateFin)}`;
    Query.getQuery(this.query)(this, dateDebut, dateFin, Query).then(result => {
      this.datas = result.rows[0];
      console.log(this.datas);
    });
  }
};
</script>

<style></style>
