// https://vuex.vuejs.org/en/state.html

export default {
  errorLine: [],
  isOnline: true,
  user: {
    username: null,
    key: null,
    admin: false
  }
};
