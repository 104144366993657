// https://vuex.vuejs.org/en/getters.html

export default {
  user: state => {
    return JSON.parse(localStorage.getItem("user"));
  },
  isUserAdmin(state) {
    return state.user.admin;
  },
  group(state) {
    return JSON.parse(localStorage.getItem("group"));
  }
};
