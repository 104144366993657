/* eslint-disable space-before-function-paren */
import { HTTP } from "@/utils/http-common";
import axios from "axios";
import Constant from "@/constant";

export default {
  list($, company) {
    return new Promise((resolve, reject) => {
      HTTP.get("/projects/manage/bnc/dataset/")
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  get_informations($, name) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/datastore/dataset/manage/${name}/`)
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          reject(err);
        });
    });
  },
  get(
    $,
    name,
    params,
    headers,
    page,
    pageSize,
    cancelToken = null,
    sort = null
  ) {
    params ? (params.page = page) : (params = { page: page });
    params.page_size = pageSize;
    params.sort = sort;

    const p =
      "?" +
      $.lo.join(
        $.lo.map(params, (o, i) => {
          return i + "=" + (typeof o === "object" ? JSON.stringify(o) : o);
        }),
        "&"
      );
    return new Promise((resolve, reject) => {
      HTTP.get(`/v1/dataset/${name}/${p.replace("?", "") ? p : ""}`, {
        ...headers,
        cancelToken: cancelToken
      })
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            resolve({ data: { results: [], count: 0 } });
          }
          reject(err);
        });
    });
  },
  search(
    $,
    name,
    headers,
    column,
    value,
    page,
    pageSize,
    cancelToken = null,
    sort = null
  ) {
    const searchParams = {
      column_name: column,
      page: page,
      page_size: pageSize,
      filter_value: value,
      sort: sort
    };

    const p =
      "?" +
      $.lo.join(
        $.lo.map(searchParams, (o, i) => {
          return (
            i +
            "=" +
            (typeof o === "object"
              ? JSON.stringify(o)
              : typeof o === "string" && o.includes("&")
              ? `"${o}"`
              : o)
          );
        }),
        "&"
      );

    return new Promise((resolve, reject) => {
      HTTP.get(`/v1/dataset/${name}/search/${p.replace("?", "") ? p : ""}`, {
        ...headers,
        cancelToken: cancelToken
      })
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            resolve({ data: { results: [], count: 0 } });
          }
          reject(err);
        });
    });
  },
  filter($, id, searchValues, page, pageSize, cancelToken = null) {
    const searchParams = [];

    for (const key in searchValues) {
      searchParams.push({
        field_name: key,
        is_filtered: true,
        query: { operator: "icontains", values: [searchValues[key]] }
      });
    }

    const searchParamsText = searchParams
      .map(param => {
        return `{"field_name":"${param.field_name}","is_filtered":${
          param.is_filtered
        },"query":${JSON.stringify(param.query)}}`;
      })
      .join(",");

    return new Promise((resolve, reject) => {
      HTTP.get(
        `/v1/dataset/${id}/${
          searchParams.length > 0 ? "?filter=[" + searchParamsText + "]" : ""
        }${
          searchParams.length > 0 ? "&" : "?"
        }page=${page}&page_size=${pageSize}`,
        { cancelToken: cancelToken }
      )
        .then(r => {
          resolve(r);
        })
        .catch(err => {
          if (axios.isCancel(err)) {
            resolve({ data: { results: [], count: 0 } });
          }
          reject(err);
        });
    });
  },
  update($, datasetName, rowToEdit, fields, showPopin = true) {
    return new Promise((resolve, reject) => {
      const key = `primary_key=${rowToEdit.primary_key}`;
      HTTP.post(`/v1/dataset/${datasetName}/by_id/${key}/`, rowToEdit)
        .then(response => {
          let successMessage = "Entrée mise à jour.";

          if (datasetName === Constant.datasetArticleBt.id) {
            successMessage += " La modification sera appliquée dans la nuit.";
          }

          if (showPopin) {
            $.$store.commit("success", successMessage);
          }
          resolve(response);
        })
        .catch(error => {
          if (showPopin) {
            $.$store.commit("error", {
              msg: "Mise à jour impossible",
              color: "error"
            });
          }
          reject(error);
        });
    });
  },
  create($, datasetName, row, showPopin = true) {
    return new Promise((resolve, reject) => {
      HTTP.post(`/v1/dataset/${datasetName}/`, row)
        .then(response => {
          if (showPopin) {
            $.$store.commit("success", "Entrée créée");
          }
          resolve(response);
        })
        .catch(error => {
          let message = "";
          for (const err in error.response.data) {
            message += "- " + err + " : " + error.response.data[err] + "\n";
          }
          if (showPopin) {
            $.$store.commit("error", {
              msg: message,
              color: "error"
            });
          }

          reject(error);
        });
    });
  },
  deleteRow($, datasetName, rowToDelete, colums) {
    let keys = {};
    keys = $.lo.join(
      $.lo.map($.lo.filter(colums, { primary_key: true }), (c, i) => {
        return `${c.name}=${rowToDelete[c.name]}`;
      }),
      ","
    );

    return new Promise((resolve, reject) => {
      HTTP.delete(`/v1/dataset/${datasetName}/by_id/${keys}/`)
        .then(response => {
          $.$store.commit("success", "Entrée supprimée");
          resolve(response);
        })
        .catch(error => {
          let message = "";
          for (const err in error.response.data) {
            message += "- " + err + " : " + error.response.data[err] + "\n";
          }
          $.$store.commit("error", {
            msg: message,
            color: "error"
          });
          reject(error);
        });
    });
  },
  exportCsv($, datasetName, fileName) {
    return new Promise((resolve, reject) => {
      HTTP.get(`/v1/dataset/${datasetName}/export/`)
        .then(r => {
          const uri = encodeURI("data:text/csv;charset=utf-8," + r.data);
          const link = document.createElement("a");
          link.setAttribute("href", uri);
          link.setAttribute(
            "download",
            fileName.toUpperCase() +
              "_" +
              new Date().toISOString().slice(0, 19) +
              ".csv"
          );
          link.click();
          resolve(link);
        })
        .catch(error => {
          $.$store.commit("error", {
            msg: error.response.data,
            color: "error"
          });
        });
    });
  },
  truncate(dataset, parent) {
    return new Promise((resolve, reject) => {
      return HTTP.get(`/datastore/dataset/manage/${dataset}/truncate`)
        .then(response => {
          parent.$store.commit("push", {
            msg: "Dataset truncated",
            color: "success"
          });
          return resolve(response);
        })
        .catch(error => {
          let errorMessage = "";

          for (let i = 0; i < error.response.data.error.length; i++) {
            if (i > 0) {
              errorMessage += "\n";
            }

            errorMessage += error.response.data.error[i];
          }

          parent.$store.commit("push", {
            msg: errorMessage,
            color: "error"
          });
          reject(error);
        });
    });
  },
  reimport(parent, project, dataset, isTruncate, file) {
    return new Promise((resolve, reject) => {
      var form = new FormData();
      form.append("project_id", project);
      form.append("truncate", isTruncate);
      form.append("file", file);
      HTTP.post(`datastore/dataset/manage/reimport/${dataset}/`, form)
        .then(response => {
          parent.$store.commit("push", {
            msg: "File reimported",
            color: "success"
          });
          return resolve(response);
        })
        .catch(error => {
          let errorMessage = "";

          for (let i = 0; i < error.response.data.error.length; i++) {
            if (i > 0) {
              errorMessage += "\n";
            }

            errorMessage += error.response.data.error[i];
          }

          parent.$store.commit("push", {
            msg: errorMessage,
            color: "error"
          });
          return reject(error);
        });
    });
  }
};
