import Constant from "@/constant";

const lecteurPlurium = [
  {
    to: "/statistiques",
    icon: "mdi-tray-full",
    text: "Prestations mensuelles",
    hide: false
  }
];

const lecteurBouygues = [
  {
    to: "/numero_serie",
    icon: "mdi-folder-open",
    text: "Suivi dossier Client",
    hide: false
  }
];

const adminBouygues = [
  {
    to: "/overview",
    icon: "mdi-finance",
    text: "Synthèse de l'activité",
    hide: false
  },
  ...lecteurBouygues,
  {
    to: "",
    icon: "mdi-book-multiple",
    text: "Articles",
    hide: false,
    children: [
      {
        to: "/references",
        text: "Références",
        hide: false
      },
      {
        to: "/stock",
        text: "Suivi des stocks",
        hide: false
      },
      {
        to: "/history",
        text: "Historique des commandes",
        hide: false,
        props: { history: true }
      }
    ]
  },
  {
    to: "",
    icon: "mdi-text-search-variant",
    text: "Supervision",
    hide: false,
    children: [
      {
        to: "/alertes",
        text: "Alertes",
        hide: false
      },
      {
        to: "/treatment",
        text: "Traitement de KOs",
        hide: false
      }
    ]
  },
  {
    to: "/debug",
    icon: "mdi-bug",
    text: "Debug",
    hide: true
  }
];

export default {
  generateLink(group) {
    switch (group) {
      case Constant.lecteur_bouygue_groupname:
        return lecteurBouygues;
      case Constant.plurium_groupname:
        return lecteurPlurium;
      case Constant.admin_bouygue_groupname:
        return adminBouygues;
      case Constant.super_admin_bouygue_groupname:
        return adminBouygues;
      default:
        return [];
    }
  }
};
