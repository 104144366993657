<template>
  <v-container fluid>
    <v-tabs
      v-model="tab"
      color="new_blue"
      slider-color="new_blue"
      background-color="transparent"
    >
      <v-tab>Profil</v-tab>
      <v-tab v-if="isAdmin">Utilisateurs</v-tab>
      <v-tab v-if="isAdmin">Groupes</v-tab>
    </v-tabs>
    <v-tabs-items v-model="tab" class="elevation-1 mt-8 rounded-xl">
      <v-tab-item>
        <v-row>
          <v-col cols="12" md="7" sm="12">
            <v-card class="elevation-0">
              <v-col cols="12" class="ml-5">
                <v-card-title>
                  <h3 class="mr-2">Informations</h3>
                  <h3>personnelles</h3>
                </v-card-title>
                <v-card-text class="mt-3">
                  <v-row>
                    <v-col cols="12">
                      <span class="font-weight-bold">Email:</span>
                      {{ profile.email }}
                    </v-col>
                    <v-col cols="12">
                      <span class="font-weight-bold">Prénom:</span>
                      {{ profile.first_name }}
                    </v-col>
                    <v-col cols="12">
                      <span class="font-weight-bold">Nom:</span>
                      {{ profile.last_name }}
                    </v-col>
                    <v-col cols="12">
                      <span class="font-weight-bold">Groupe:</span>
                      {{ group }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-col>
            </v-card>
          </v-col>
          <v-col cols="12" md="5" sm="12">
            <v-card class="elevation-0">
              <v-card-title class="justify-center"
                >Changement de mot de passe</v-card-title
              >
              <v-card-text>
                <v-row class="justify-center">
                  <v-col cols="8">
                    <password-field
                      v-model="password.old"
                      label="Mot de passe actuel"
                      outlined
                      v-on:keyup.enter="changePassword"
                      ref="passwordOld"
                    />
                    <password-field
                      v-model="password.new"
                      outlined
                      label="Nouveau mot de passe"
                      complexity-requirements
                      v-on:keyup.enter="changePassword"
                      ref="passwordNew"
                    />
                    <password-field
                      v-model="password.newRepeat"
                      :same-as="password.new"
                      outlined
                      label="Confirmation du mot de passe"
                      v-on:keyup.enter="changePassword"
                      ref="passwordNewRepeat"
                    />
                  </v-col>
                </v-row>
                <v-row class="justify-center">
                  <v-col cols="6">
                    <v-btn
                      block
                      color="new_blue"
                      class="white--text"
                      @click="changePassword"
                      :disabled="isChangePasswordButtonDisabled"
                    >
                      mettre a jour
                    </v-btn>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-tab-item>
      <v-tab-item v-if="isAdmin">
        <users-crud
          :authorized-groups="authorizedGroups"
          :refresh-key="refreshKey"
          @refresh="refreshKey = !refreshKey"
        />
      </v-tab-item>
      <v-tab-item v-if="isAdmin">
        <group-company-settings-table
          :authorized-groups="authorizedGroups"
          :refresh-key="refreshKey"
          @refresh="refreshKey = !refreshKey"
        />
      </v-tab-item>
    </v-tabs-items>
  </v-container>
</template>

<script>
import AccountService from "@/services/AccountService";
import { required, sameAs, minLength } from "vuelidate/lib/validators";
import { validationMixin } from "vuelidate";
import User from "@/views/admin/Users.vue";
import Constant from "@/constant";
import GroupCompanySettingsTable from "@/components/hmd/GroupCompanySettingsTable";
import UsersCrud from "@/components/hmd/Users/UsersCrud";
import { isComplexPassword } from "@/utils/regex_validator";
import PasswordField from "@/components/base/PasswordField";

export default {
  components: {
    PasswordField,
    UsersCrud,
    GroupCompanySettingsTable
  },
  mixins: [validationMixin],
  validations: {
    password: {
      old: { required },
      new: {
        required,
        isComplexPassword
      },
      newRepeat: {
        required,
        sameAsNew: sameAs(function() {
          return this.password.new;
        }),
        isComplexPassword
      }
    },
    validationGroup: ["password", "profile"]
  },
  data() {
    return {
      tab: 0,
      loading: false,

      profileCopy: {
        email: "",
        first_name: "",
        last_name: ""
      },
      profile: {
        email: "",
        first_name: "",
        last_name: ""
      },
      password: {
        old: "",
        new: "",
        newRepeat: ""
      },
      admins: Constant.admin_groups(),
      group: this.$store.getters.group,
      authorizedGroups: Constant.fetch_users_from_group(
        this.$store.getters.group
      ),
      refreshKey: false
    };
  },
  computed: {
    isChangePasswordButtonDisabled() {
      return (
        !this.password.old ||
        !this.password.new ||
        !this.password.newRepeat ||
        this.$v.password.$invalid
      );
    },
    hasChanged() {
      return !this.lo.isEqual(this.profileCopy, this.profile);
    },
    isAdmin() {
      return this.admins.includes(this.group);
    }
  },
  created() {
    var t = this;
    this.loading = true;

    AccountService.profile(this)
      .then(function(response) {
        t.profile = response;
        t.profileCopy = t.lo.cloneDeep(response);
      })
      .finally(() => {
        t.loading = false;
      });
  },
  methods: {
    updateProfileInfo() {
      const t = this;
      AccountService.updateProfileInfo(
        this,
        this.profile.first_name,
        this.profile.last_name,
        this.profile.email
      ).then(r => {
        t.profileCopy = t.lo.cloneDeep(t.profile);
      });
    },
    changePassword() {
      const $ = this;
      $.$v.password.$touch();
      if (!$.$v.password.$invalid) {
        AccountService.changePassword(
          $,
          $.password.old,
          $.password.new,
          $.password.newRepeat
        ).then(r => {
          // Clean all password fields in case of a success
          this.$refs.passwordOld.reset();
          this.$refs.passwordNew.reset();
          this.$refs.passwordNewRepeat.reset();
        });
      }
    }
  }
};
</script>
