<template>
  <v-dialog v-model="dialogArticle" max-width="650px">
    <template v-slot:activator="{ on: onDialogArticle }">
      <v-container>
        <v-col>
          <v-row class="align-center">
            <v-col cols="4">
              <v-text-field
                v-model="search"
                placeholder="Recherche"
                append-icon="mdi-magnify"
              />
            </v-col>
            <v-spacer></v-spacer>
            <v-btn
              v-if="isSuperAdmin"
              small
              plain
              class="filter_btn"
              @click="handleOnArticleDialog(onDialogArticle, null, $event)"
            >
              Créer
              <v-icon class="primary--text">mdi-plus</v-icon>
            </v-btn>
          </v-row>
          <v-row class="xs justify-center">
            <v-card class="rounded-xl pa-2 elevation-0" min-width="100%">
              <hmd-table
                ref="tableReference"
                :recherche="search"
                paginate
                :conditional-format="[
                  {
                    cols: ['reference'],
                    text: 'P/N',
                    condition: v => v > 40,
                    style: {}
                  },
                  {
                    cols: ['ref_o3se'],
                    text: 'Modèle O3SE',
                    condition: v => v > 40,
                    style: {}
                  },
                  {
                    cols: ['destination'],
                    text: 'Destination Flux Résilisation',
                    condition: v => v > 40,
                    style: {}
                  },
                  {
                    cols: ['destination_frd'],
                    text: 'Destination Flux FRD',
                    condition: v => v > 40,
                    style: {}
                  },
                  {
                    cols: ['famille'],
                    text: 'Constructeur',
                    condition: v => v > 40,
                    style: {}
                  },
                  {
                    cols: ['button'],
                    text: 'Modifier'
                  }
                ]"
                :dataset="datasets.article_bt"
                :ignored-columns="ignoredColumns"
                :iseditable="modification"
                :disable-sort="true"
                :default-sort="{ field_name: 'reference', order: 'asc' }"
                :refresh-key="refreshKey"
                :transform-value-column="['destination', 'destination_frd']"
                :transform-value-mapping="referenceDestinationsToDict"
                :edit-function="
                  handleOnArticleDialog.bind(this, onDialogArticle)
                "
                :is-edit-disabled="
                  item =>
                    item.destination === 'Autre Opérateur' ||
                    item.destination_frd === 'Autre Opérateur' ||
                    !isSuperAdmin
                "
                :search-column="[
                  'reference',
                  'famille',
                  'ref_o3se',
                  'destination'
                ]"
                @modification="modificationEvent"
                @rowevent="openDialogForRow"
              />
            </v-card>
            <v-dialog v-model="dialog" max-width="500px">
              <v-card class="rounded-xl">
                <v-card-title>
                  <v-col cols="5">
                    <span class="text-h6 primary--text">Informations</span>
                  </v-col>
                  <v-col cols="7" class="text-right">
                    <v-btn icon class="primary--text" @click="close">
                      <v-icon>mdi-close</v-icon>
                    </v-btn>
                  </v-col>
                </v-card-title>
                <v-card-subtitle class="ml-3 mt-n8">
                  <span class="text-subtitle-1">
                    sur la référence
                    <span class="font-weight-medium">
                      {{ dialogData.reference }}
                    </span>
                  </span>
                </v-card-subtitle>
                <v-card-text>
                  <v-row class="ml-0 mb-2 mt-0">
                    <v-col cols="12" class="text-subtitle-1 my-n1">
                      <span class="font-weight-bold">Gencode : </span>
                      {{ dialogData.gencode }}
                    </v-col>
                    <v-col cols="12" class="text-subtitle-1 my-n1">
                      <span class="font-weight-bold">Valeur du CPE : </span>
                      {{ `${dialogData.valeur_bytel} €` }}
                    </v-col>
                    <v-col cols="12" class="text-subtitle-1 my-n1">
                      <span class="font-weight-bold">
                        Destination Flux résiliation :
                      </span>
                      {{ dialogData.destination }}
                    </v-col>
                    <v-col cols="12" class="text-subtitle-1 my-n1">
                      <span class="font-weight-bold"
                        >Destination flux FRD :
                      </span>
                      {{ dialogData.destination_frd }}
                    </v-col>
                    <v-col cols="12" class="text-subtitle-1 my-n1">
                      <span class="font-weight-bold">Alim incluse : </span>
                      {{ dialogData.alim_incluse ? "Oui" : "Non" }}
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-container>
    </template>
    <v-card class="pb-4">
      <v-card-title class="mb-n6">
        <v-col cols="5">
          <span class="text-h6 primary--text">
            {{
              articleCreationForm.new
                ? "Création article"
                : "Modification article"
            }}
          </span>
        </v-col>
        <v-col cols="7" class="text-right">
          <v-btn icon class="primary--text" @click="closeCreate">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12"> </v-col>
          <v-col cols="12" class="ma-0 py-0">
            <v-text-field
              ref="reference"
              v-model="articleCreationForm.reference"
              :disabled="!articleCreationForm.new"
              name="reference"
              :rules="[rules.required]"
              outlined
              required
            >
              <template v-slot:label>
                P/N <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              ref="famille"
              v-model="articleCreationForm.famille"
              :disabled="!articleCreationForm.new"
              name="famille"
              outlined
              :rules="[rules.required]"
              required
            >
              <template v-slot:label>
                Constructeur
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              ref="ref_o3se"
              v-model="articleCreationForm.ref_o3se"
              :disabled="!articleCreationForm.new"
              name="ref_o3se"
              outlined
              :rules="[rules.required]"
              required
            >
              <template v-slot:label>
                Référence O3SE
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              ref="gencode"
              v-model="articleCreationForm.gencode"
              :disabled="!articleCreationForm.new"
              name="gencode"
              outlined
              :rules="[rules.required, rules.intOnly]"
              required
              type="number"
            >
              <template v-slot:label>
                Gencode
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-text-field
              ref="valeur_bytel"
              v-model="articleCreationForm.valeur_bytel"
              name="valeur_bytel"
              outlined
              :rules="[rules.required, rules.intOnly]"
              required
              type="number"
            >
              <template v-slot:label>
                Valeur du CPE en euros
                <span class="new_orange--text">*</span>
              </template>
            </v-text-field>
            <v-select
              ref="destination"
              v-model="articleCreationForm.destination"
              :items="referenceDestinations"
              outlined
              :rules="[rules.required]"
              required
            >
              <template v-slot:label>
                Destination Flux résiliation
                <span class="new_orange--text">*</span>
              </template>
            </v-select>
            <v-select
              ref="destination_frd"
              v-model="articleCreationForm.destination_frd"
              :items="referenceDestinations"
              outlined
              :rules="[rules.required]"
              required
            >
              <template v-slot:label>
                Destination Flux FRD
                <span class="new_orange--text">*</span>
              </template>
            </v-select>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mx-2 mt-n6">
        <v-btn
          color="new_orange"
          class="white--text"
          large
          block
          :disabled="loadingCreationPopup"
          @click="validateCreation"
        >
          {{ articleCreationForm.new ? "Créer" : "Modifier" }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Table from "@/components/bnc/dashboard/Table";
import DatasetService from "@/services/DatasetService";
import QueryService from "@/services/QueryService";
import Constant from "@/constant";

export default {
  components: {
    "hmd-table": Table
  },
  data() {
    return {
      datasets: {
        article_bt: Constant.datasetArticleBt.id,
        flux_label: Constant.datasetFluxLabel.id
      },
      ignoredColumns: [
        "a_tester_frd",
        "a_tester_resil",
        "alim_incluse",
        "alim_price",
        "autre_operateur",
        "cable",
        "carte",
        "commentaire",
        "echangeable",
        "gencode",
        "idcable1",
        "idcable2",
        "idcable3",
        "idcable4",
        "idcable5",
        "libelle",
        "macaddress_materiel",
        "materiel_4g_5g",
        "photo",
        "poids",
        "primary_key",
        "prix_alim",
        "prix_swap",
        "prix_vente_t_1",
        "prix_vente",
        "pv_trimestre",
        "pv_trimestre_suiv",
        "qte_a_renvoyer",
        "ref_bte",
        "reference_flash",
        "reparation",
        "reparator_1",
        "reparator_1_price",
        "reparator_2",
        "reparator_2_price",
        "swap_par",
        "upgrade_ram",
        "valeur_bytel",
        "valeur_wholesale"
      ],
      modification: false,
      dialog: false,
      dialogData: "",
      dialogArticle: false,
      articleCreationForm: {
        new: true,
        private_key: null,
        reference: "",
        famille: "",
        ref_o3se: "",
        gencode: null,
        valeur_bytel: null,
        destination: null,
        destination_frd: null
      },
      rules: {
        required: value =>
          (value !== null && value !== undefined && value !== "") ||
          "Champ requis.",
        intOnly: value => {
          try {
            parseInt(value);
            if (value % 1 !== 0) {
              return "Veuillez entrer un nombre entier.";
            }
          } catch (error) {
            return "Veuillez entrer un nombre entier.";
          }
          return true;
        },
        notTooLarge: value => {
          // prevent excessive values, which slows down website
          if (value > 1000000000) {
            return "Veuillez entrer un nombre plus petit.";
          }
          return true;
        }
      },
      formHasErrors: false,
      refreshKey: false,
      referenceDestinations: [],
      search: "",
      flux_labels: null,
      loadingCreationPopup: false
    };
  },
  computed: {
    areDestinationsDisabled() {
      if (!this.$store.getters?.users?.admin) {
        return false;
      }

      return [
        this.articleCreationForm?.destination ?? 0,
        this.articleCreationForm?.destination_frd ?? 0
      ].includes(3);
    },
    isSuperAdmin() {
      return (
        this.$store.getters.group === Constant.super_admin_bouygue_groupname
      );
    },
    referenceDestinationsToDict() {
      const dict = {};
      this.referenceDestinations.forEach(dest => {
        dict[dest.value] = dest.text;
      });
      return dict;
    }
  },
  mounted() {
    DatasetService.get(this, this.datasets.flux_label, "", "", 1, 100).then(
      data => {
        this.flux_labels = data.data.results;
        this.referenceDestinations = this.flux_labels.map(f => ({
          text: f.destination_str,
          value: f.destination_int
        }));
      }
    );
  },
  methods: {
    destToNumber(dest) {
      return this.referenceDestinations.find(d => d.text === dest)?.value ?? 4;
    },
    numberToDest(num) {
      return (
        this.referenceDestinations.find(d => +d.value === +num)?.text ??
        this.referenceDestinations
      );
    },
    handleOnArticleDialog(on, item, e) {
      this.articleCreationForm = {
        private_key: null,
        reference: "",
        famille: "",
        ref_o3se: "",
        gencode: null,
        valeur_bytel: null,

        ...item,
        new: !item,

        destination: this.destToNumber(item?.destination) || null,
        destination_frd: this.destToNumber(item?.destination_frd) || null
      };

      on.click(e);
    },
    modificationEvent(event) {
      this.modification = event;
    },
    openDialogForRow(event) {
      this.dialogData = event;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    closeCreate() {
      this.dialogArticle = false;
    },
    validateCreation() {
      this.formHasErrors = false;

      Object.keys(this.articleCreationForm).forEach(f => {
        if (!(f in this.$refs)) return;

        if (!this.articleCreationForm[f]) this.formHasErrors = true;
        this.$refs[f].validate(true);
      });

      if (this.formHasErrors) return;

      this.loadingCreationPopup = true;

      const createRow = {
        ...this.articleCreationForm,
        part_number: this.articleCreationForm.reference,
        fabricant: this.articleCreationForm.famille,
        gencode: this.articleCreationForm.gencode.toString(),
        valeur_bytel: parseInt(this.articleCreationForm.valeur_bytel)
      };

      if (this.articleCreationForm.new) {
        QueryService.sendEmail(
          this,
          createRow,
          "Demande de création d'article",
          "email_new_article"
        )
          .then(() => {
            this.closeCreate();
            this.refreshKey = !this.refreshKey;
            this.$store.commit("success", "test");
          })
          .finally(() => {
            this.loadingCreationPopup = false;
          });
      } else {
        DatasetService.update(
          this,
          this.datasets.article_bt,
          createRow,
          Object.keys(createRow)
        )
          .then(() => {
            this.closeCreate();
            this.refreshKey = !this.refreshKey;
          })
          .finally(() => {
            this.loadingCreationPopup = false;
          });
      }
    }
  },
  beforeRouteLeave(to, from, next) {
    const message =
      "Vous êtes en pleine modification, si vous quittez maintenant vos modifications ne seront pas sauvegardés. Souhaitez-vous sauvegarder avant de continuer ?";
    if (this.modification) {
      this.$store.commit("confirm", {
        message: message,
        title: "Modification en attente",
        color: "warning",
        onCancel: () => {
          next();
        },
        onConfirm: () => {
          this.$refs.tableReference.enableModification();
        }
      });
    } else {
      next();
    }
  }
};
</script>

<style></style>
