<template>
  <v-container>
    <event-expand-panel />
  </v-container>
</template>

<script>
import EventExpandPanel from "@/components/bytel/EventExpandPanel";

export default {
  components: {
    EventExpandPanel
  },
  data() {
    return {};
  }
};
</script>
