export default {
  datasetAlertesKo: {
    name: '"alertes_ko"',
    id: "0837e6d5-a093-41ab-937c-bf5670c2f4e0"
  },
  datasetArticleBt: {
    name: '"article_bt"',
    id: "10ebb4b3-1e26-4ea6-8e13-18dd8f06f7c2"
  },
  datasetCityCodes: {
    name: '"city_codes"',
    id: "35ca148d-f08f-4ae5-bb79-ddafb28be966"
  },
  datasetCoutRse: {
    name: '"cout_rse"',
    id: "df53a241-c9e3-4ec3-98ef-e88bdb75e4c4"
  },
  datasetEnlevement: {
    name: '"enlevement"',
    id: "4a1332cd-2cb4-4fb7-bb43-412ba9d1eed7"
  },
  datasetFacturationAddedColumn: {
    name: '"facturation_added_column"',
    id: "13bb42fa-496b-45c8-a2a0-7be94f41b378"
  },
  datasetFacturationBte: {
    name: '"FACTURATION_BTE"',
    id: "ffc99979-a9a9-44b4-ab8d-9585bd9541dd"
  },
  datasetFacturationHistorique: {
    name: '"facturation_historique"',
    id: "21c73176-6435-4710-b316-5c3f01b6d60e"
  },
  datasetFacturationPrixUnitaire: {
    name: '"facturation_prix_unitaire"',
    id: "aafe60e3-8dbe-4b33-b6c4-1f40660fc612"
  },
  datasetFlux: {
    name: '"flux"',
    id: "205d9a20-3491-497c-a2c7-bbd5d9c0b64c"
  },
  datasetFluxLabel: {
    name: '"flux_label"',
    id: "90bd1618-19b5-4c26-ab52-f2a06dbc28c9"
  },
  datasetHistoriqueCommandes: {
    name: '"historique_commandes"',
    id: "c001b1bc-da7e-48a8-86b4-8071560851db"
  },
  datasetLigEnlevement: {
    name: '"LIG_ENLEVEMENT"',
    id: "abeee4e5-b9b4-4952-b7e3-abfd7a990404"
  },
  datasetMailingList: {
    name: '"mailing_list"',
    id: "1d2683d9-5864-4dfa-b652-1afa2091d152"
  },
  datasetRecettes: {
    name: '"recettes"',
    id: "f4d3b4e4-dbe1-411b-8358-da874d757dd4"
  },
  datasetSuiviStock: {
    name: '"suivi_stock"',
    id: "8297002f-c5f8-4757-ae3b-e942263d6689"
  },
  datasetSuiviStockOld: {
    name: '"suivi_stock_old"',
    id: "2cd93f9f-7df7-4e40-8390-6d55fe5203a2"
  },
  datasetVentesBte: {
    name: '"ventes_bte"',
    id: "bb5afce9-525d-41ae-926e-789f1f5bd0c8"
  },
  query_depense: {
    name: "depense",
    id: "8883ab00-847e-43e3-9b1c-5311b73b42f4"
  },
  query_ventes_bte_unites: {
    name: "ventes_bte_unites",
    id: "9dd9cd7f-1a00-4a00-b102-aa81911de514"
  },
  query_ventes_bte_montant: {
    name: "ventes_bte_montant",
    id: "7b4f3f90-13aa-49bd-90c4-c3be85ac7cb7"
  },
  query_cpe_all: {
    name: "cpe_all",
    id: "0ea7277b-9c6f-42b8-86a8-03522bdc3e57"
  },
  query_repartition: {
    name: "repartition",
    id: "28deb35c-f98c-4fa4-a995-0f09a7a23d4e"
  },
  query_cout_rse: {
    name: "cout_rse",
    id: "0db02159-bc91-41a0-b36b-5267a851c4f1"
  },
  query_total_recettes_rachat_cpe: {
    name: "total_recettes_rachat_cpe",
    id: "954edacb-5348-4dc8-bef2-e00ebb8e94e1"
  },
  query_ventes_bte: {
    name: "ventes_bte",
    id: "b4fd7b07-28b0-4994-9c6e-249bb9817bd1"
  },
  query_recettes: {
    name: "recettes",
    id: "2d8ceb88-e2bd-4494-95dc-5465fd826d51"
  },
  query_facturation: {
    name: "facturation",
    id: "ee5dffd4-5a43-49bd-ac57-19b215d408a8"
  },
  query_headersFacturation: {
    name: "headersFacturation",
    id: "d21964f3-51ff-430e-ac45-582716792da8"
  },
  query_flux_sortant: {
    name: "flux_sortant",
    id: "8c75d522-9d12-4d45-a42b-e702eaf570be"
  },
  query_flux_entrant: {
    name: "flux_entrant",
    id: "84e5d4aa-b2ae-4fe3-81be-60967313e7f0"
  },
  query_recettes_montant: {
    name: "recettes_montant",
    id: "7c72045f-f73f-4f18-a4de-3e3d493d264f"
  },
  query_amount_cpes_purchased_last_month: {
    name: "amount_cpes_purchased_last_month",
    id: "9460bd30-e9bc-44c6-94c6-ea018944d487"
  },
  query_materiel_recu_last_month: {
    name: "materiel_recu_last_month",
    id: "9c9f293b-dc49-4df2-989e-bc0a12e8e968"
  },
  query_depense_total_last_month: {
    name: "depense_total_last_month",
    id: "d9823138-70b0-407d-9e49-b8c2109faf26"
  },
  query_depense_transport_last_month: {
    name: "depense_transport_last_month",
    id: "eef45b1e-a5c0-4666-9e5a-65020ed25720"
  },
  query_vie_numero_serie: {
    name: "vie_numero_serie",
    id: "bf5e2ab2-9a5e-481d-81ba-649a1ff87fc4"
  },
  query_cpe_direct: {
    name: "cpe_direct",
    id: "c485cb1d-a1f3-48d7-b249-bd998ef274e4"
  },
  query_clef_cti: {
    name: "clef_cti",
    id: "870fce55-4649-47d9-bac2-a50e847bef0b"
  },
  query_cpe_recu: {
    name: "cpe_recu",
    id: "f60b2cc6-b9fb-476d-baa5-8c56ed2f1677"
  },
  kpi_amount_cpes_purchased_last_month: {
    name: "amount_cpes_purchased_last_month",
    id: "16"
  },
  kpi_depense_total_last_month: {
    name: "depense_total_last_month",
    id: "17"
  },
  kpi_materiel_recu_last_month: {
    name: "materiel_recu_last_month",
    id: "18"
  },
  kpi_depense_transport_last_month: {
    name: "depense_transport_last_month",
    id: "19"
  },
  kpi_plurium_activity_billing: {
    name: "plurium_activity_billing",
    id: "20"
  },
  kpi_recettes: {
    name: "recettes",
    id: "21"
  },
  company: "bouygues-telecom",
  // Only replace upper part of file with response of 'constants' endpoint
  triggerCronDatasetFacturationBTEPK: 8,
  project: process.env.VUE_APP_PROJECT_ID,
  default_group_id: 11, // This is the id of the default group of the company
  lecteur_bouygue_groupname: "BTE-Utilisateur",
  admin_bouygue_groupname: "BTE-Admin",
  super_admin_bouygue_groupname: "BTE-SuperAdmin",
  plurium_groupname: "Plurium",
  admin_groups: function() {
    return [this.admin_bouygue_groupname, this.super_admin_bouygue_groupname];
  },
  super_admin_groups: function() {
    return [this.super_admin_bouygue_groupname];
  },
  groups: function() {
    return [
      this.admin_bouygue_groupname,
      this.super_admin_bouygue_groupname,
      this.plurium_groupname,
      this.lecteur_bouygue_groupname
    ];
  },
  fetch_users_from_group(group) {
    switch (group) {
      case this.admin_bouygue_groupname:
      case this.super_admin_bouygue_groupname:
        return [
          this.admin_bouygue_groupname,
          this.super_admin_bouygue_groupname,
          this.lecteur_bouygue_groupname
        ];
      case this.plurium_groupname:
        return [this.plurium_groupname];
      default:
        return null;
    }
  },
  DEBUG: false
};