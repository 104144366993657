<template>
  <v-card class="elevation-0 pa-6 rounded-xl">
    <!-- Create dialog component -->
    <v-dialog
      v-if="create_dialog"
      v-model="create_dialog"
      persistent
      :max-width="isSuperAdmin ? '850px' : '600'"
    >
      <v-card class="pa-2">
        <v-row class="pt-2 mx-4">
          <v-spacer></v-spacer>
          <v-col cols="1" class="pr-0">
            <v-btn icon @click="create_dialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-card-title>
          <v-row>
            <v-col
              v-if="isSuperAdmin"
              class="primary--text"
              :cols="isSuperAdmin ? '6' : '12'"
            >
              {{ editMode ? "Edition du groupe" : "Création d'un groupe" }}
            </v-col>
            <v-col v-else class="primary--text" cols="12">
              Voir un groupe
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="isSuperAdmin" class="primary--text" cols="5">
              Ajouter un membre
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text class="mt-6">
          <v-row>
            <v-col :cols="isSuperAdmin ? '5' : '12'">
              <v-row>
                <v-text-field
                  v-model="groupForm.name"
                  :disabled="editMode"
                  single-line
                  required
                  outlined
                  hint="Nom du groupe"
                  persistent-hint
                  class="mb-6"
                />
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card
                    elevation="0"
                    max-height="330px"
                    class="overflow-auto pa-4"
                  >
                    <v-row
                      v-for="user in groupForm.users"
                      :key="user.id"
                      class="rounded-ld border"
                    >
                      <v-text-field v-model="user.email" readonly outlined>
                        <template v-if="isSuperAdmin" v-slot:append>
                          <!-- Button for group modification -->
                          <v-btn
                            v-if="editMode"
                            color="new_orange"
                            class="mt-n2"
                            icon
                            @click="removeUser(user.pk)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                          <!-- Button for group creation -->
                          <v-btn
                            v-else
                            color="new_orange"
                            class="mt-n2"
                            icon
                            @click="removeUserFromGroupCreation(user)"
                          >
                            <v-icon>mdi-delete</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
            <v-spacer></v-spacer>
            <v-col v-if="isSuperAdmin" cols="6">
              <v-row>
                <v-text-field
                  v-model="userSearch"
                  label="Rechercher"
                  append-icon="mdi-magnify"
                />
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-card
                    elevation="0"
                    max-height="425px"
                    class="overflow-auto py-4"
                  >
                    <v-row
                      v-for="user in filteredUsers"
                      :key="user.id"
                      class="rounded-lg border mx-2"
                    >
                      <v-text-field v-model="user.email" readonly outlined>
                        <template v-slot:append>
                          <!-- Button for group modification -->
                          <v-btn
                            v-if="editMode"
                            class="new_orange white--text"
                            small
                            icon
                            @click="addUserToGroupInstant(user)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                          <!-- Button for group creation -->
                          <v-btn
                            v-else
                            class="new_orange white--text"
                            small
                            icon
                            @click="addUserToGroupCreation(user)"
                          >
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                      </v-text-field>
                    </v-row>
                  </v-card>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- Main component -->
    <v-card-title class="font-weight-bold">
      Administration des groupes
    </v-card-title>
    <v-card-text>
      <v-row align="center">
        <v-col class="ml-3" cols="3" align-self="center">
          <v-text-field
            v-model="search"
            label="Rechercher"
            single-line
            hide-details
            append-icon="mdi-magnify"
            class="mb-2"
          />
        </v-col>
      </v-row>
      <v-data-table
        :items="groups"
        :headers="columns"
        :search="search"
        :loading="loading"
        hide-default-footer
        class="mt-6"
      >
        <template v-slot:item.permissions.codename="{ item }">
          <v-chip
            v-for="permission in item.permissions"
            :key="permission.id"
            class="mt-1"
          >
            {{ permission.codename }}
          </v-chip>
        </template>
        <template v-slot:item.object_permissions.codename="{ item }">
          <v-chip
            v-for="permission in item.object_permissions"
            :key="permission.id"
            class="mt-1"
          >
            {{ permission.codename }} on
            {{ get_object_name(permission.codename) }}
            {{ permission.object_name }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn icon color="primary" @click="openUpdateDialog(item)">
            <v-icon v-if="isSuperAdmin">
              mdi-pencil
            </v-icon>
            <v-icon v-else>
              mdi-eye
            </v-icon>
          </v-btn>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>
<script>
import CompanyGroupService from "@/services/CompanyGroupService";
import CompanyService from "@/services/CompanyService";
import Constant from "@/constant";

export default {
  props: {
    authorizedGroups: {
      type: Array,
      default: () => []
    },
    refreshKey: {
      type: Boolean,
      default: false
    }
  },
  data: function() {
    return {
      loading: true,
      create_dialog: false,
      // this value defines if the create dialog is used as a modifying dialog
      editMode: false,
      groups: [],
      group: this.$store.getters.group,
      admins: [...Constant.admin_groups()],
      superAdmins: [...Constant.super_admin_groups()],
      columns: [
        {
          text: "Nom du groupe",
          value: "name",
          align: "left",
          class: "text-h5 black--text"
        }
      ],
      search: "",
      userSearch: "",
      selectedGroup: null,
      groupForm: {
        name: "",
        users: []
      },
      companyId: this.$store.getters.user.company_id,
      create_loading: false,
      edit_dialog: false,
      edit_loading: false,
      group_update_form_old_name: "",
      users: null,
      selectedUser: null
    };
  },
  computed: {
    filteredUsers() {
      if (!this.users) return [];
      return this.users.filter(user => {
        const usersInGroup = this.groupForm.users.map(u => u.email);
        return (
          user.email.toLowerCase().includes(this.userSearch.toLowerCase()) &&
          !usersInGroup.includes(user.email)
        );
      });
    },
    isSuperAdmin() {
      return this.superAdmins.includes(this.group);
    }
  },
  watch: {
    refreshKey() {
      this.reset();
    }
  },
  created() {
    if (this.admins.includes(this.group)) {
      this.columns.push({
        text: "Actions",
        value: "actions",
        align: "left",
        sortable: false,
        class: "text-h6 subtitle--text"
      });
    }
  },
  mounted() {
    this.init();
  },
  methods: {
    get_object_name(codename) {
      let obj = codename.split("_");
      obj = obj[obj.length - 1];
      obj = obj.charAt(0).toUpperCase() + obj.slice(1);
      return obj;
    },
    get_permission(codename) {
      let obj = codename.split("_");
      obj.splice(obj.length - 1, 1);
      obj = obj.join("_");
      return obj;
    },
    get_type_permission(codename) {
      let obj = codename.split("_");
      obj = obj[obj.length - 1];
      return obj;
    },
    getGroupIndex(idGroup) {
      return this.groups.findIndex(g => g.id === idGroup);
    },
    getMemberIndex(idGroup, idUser) {
      const group = this.groups[this.getGroupIndex(idGroup)];
      return group.members.findIndex(m => m.pk === idUser);
    },
    getIndexPermission(idGroup, idPermission) {
      const group = this.groups[this.getGroupIndex(idGroup)];
      return group.permissions.findIndex(p => p.id === idPermission);
    },
    getIndexObjectPermission(idGroup, idPermission) {
      const group = this.groups[this.getGroupIndex(idGroup)];
      return group.object_permissions.findIndex(p => p.id === idPermission);
    },
    confirmDeconsteGroup(idGroup) {
      this.selectedGroup = idGroup;
      this.$store.commit("confirm", {
        text:
          "Do you really want to deconste this groups ? This action cannot be undone, be careful.",
        confirmBtnTxt: "DEconstE",
        onConfirm: this.deconsteGroup,
        onCancel: this.cancelDeconsteGroup
      });
    },
    deconsteGroup() {
      CompanyGroupService.deconste_group_company(this, this.selectedGroup).then(
        () => {
          const index = this.getGroupIndex(this.selectedGroup);
          this.groups.splice(index, 1);
        }
      );
    },
    cancelDeconsteGroup() {
      this.selectedGroup = null;
    },
    openUpdateDialog(group) {
      this.selectedGroup = group.id;
      this.groupForm.name = group.name;
      this.groupForm.users = group.members;
      this.editMode = true;
      this.displayGroupCreationDialog();
      const index = this.getGroupIndex(group.id);
      this.group_update_form_old_name = this.groupForm.name;
    },
    removeUser(idUser) {
      CompanyGroupService.remove_member_group_company(
        this,
        this.selectedGroup,
        idUser
      )
        .then(result => {
          const groupIndex = this.getGroupIndex(this.selectedGroup);
          const memberIndex = this.getMemberIndex(this.selectedGroup, idUser);
          this.groups[groupIndex].members.splice(memberIndex, 1);

          const formMemberIndex = this.groupForm.users.findIndex(
            u => u.pk === idUser
          );
          if (formMemberIndex !== -1) {
            this.groupForm.users.splice(formMemberIndex, 1);
          }

          this.$store.commit("success", "Membre supprimé avec succès");
        })
        .finally(() => {
          this.init();
          this.$emit("refresh");
        });
    },
    addUser(snackBar = false) {
      if (this.selectedUser != null) {
        CompanyGroupService.add_member_group_company(
          this,
          this.selectedGroup,
          this.selectedUser.pk
        )
          .then(result => {
            const groupIndex = this.getGroupIndex(this.selectedGroup);
            this.groups[groupIndex].members.push(this.selectedUser);
            this.selectedUser = null;
            if (snackBar) {
              this.$store.commit("success", "Membre ajouté avec succès");
            }
          })
          .finally(() => {
            this.init();
            this.$emit("refresh");
          });
      }
    },
    removePermission(idPermission, codename) {
      CompanyGroupService.remove_permission_group_company(
        this,
        this.selectedGroup,
        [this.get_permission(codename)],
        this.get_type_permission(codename)
      ).then(result => {
        const indexGroup = this.getGroupIndex(this.selectedGroup);
        const indexPermission = this.getIndexPermission(
          this.selectedGroup,
          idPermission
        );
        this.groups[indexGroup].permissions.splice(indexPermission, 1);
      });
    },
    removeObjectPermission(idPermission, codename, objectPk) {
      CompanyGroupService.remove_permission_group_company(
        this,
        this.selectedGroup,
        [this.get_permission(codename)],
        this.get_type_permission(codename),
        objectPk
      ).then(result => {
        const indexGroup = this.getGroupIndex(this.selectedGroup);
        const indexPermission = this.getIndexObjectPermission(
          this.selectedGroup,
          idPermission
        );
        this.groups[indexGroup].object_permissions.splice(indexPermission, 1);
      });
    },
    displayGroupCreationDialog() {
      this.create_dialog = true;
    },
    addUserToGroupCreation(user) {
      this.groupForm.users.push(user);
    },
    removeUserFromGroupCreation(user) {
      const index = this.groupForm.users.indexOf(user);
      this.groupForm.users.splice(index, 1);
      if (this.editMode) {
        this.usersToRemove.push(user);
      }
    },
    addUserToGroupInstant(user) {
      this.selectedUser = user;
      this.addUser(true);
    },
    reset() {
      this.groups = [];
      this.distinctGroups = [];
      this.users = [];
      this.init();
    },
    init() {
      this.loading = true;
      CompanyGroupService.list_group_company(this).then(result => {
        if (this.authorizedGroups.length > 0) {
          this.groups = result.filter(g => {
            return this.authorizedGroups.includes(g.name);
          });
        } else {
          this.groups = result;
        }
      });

      return CompanyService.list_user_except_plurium(this, Constant.company)
        .then(r => {
          r.results = r.results.map(u => {
            u.pk = u.id;
            u.company = Constant.company;
            return u;
          });

          // Get distinct groups
          const groups = new Set();
          r.results.forEach(user => {
            user.groups.forEach(group => {
              groups.add(group);
            });
          });
          this.distinctGroups = Array.from(groups);

          // Translate groups ids to names

          const groupNames = {};
          const groupPromises = this.distinctGroups.map(group => {
            return CompanyGroupService.get_group_company(this, group).then(
              r => {
                groupNames[group] = r.name;
              }
            );
          });

          Promise.all(groupPromises).then(() => {
            // Change group ids to group names in users list
            r.results = r.results.map(u => {
              u.groups = [groupNames[u.groups[0]]];
              return u;
            });

            this.users = r.results;
          });
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
};
</script>
