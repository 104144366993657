<template>
  <v-card :elevation="card ? 1 : 0" height="100%" width="100%">
    <v-card-title v-if="title">
      <v-row>
        <v-col sm="6">
          <div class="text-text-subtitle-1 font-weight-bold">
            {{ title }}
          </div>
        </v-col>
        <v-col v-if="iseditable" sm="4" md="3" lg="1">
          <v-btn
            icon
            :color="modification ? 'accent' : ''"
            :style="{ position: 'absolute', right: '85px' }"
            @click="enableModification"
          >
            <v-icon>
              mdi-pen
            </v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-card-title>
    <v-dialog v-model="openDialog" width="450px">
      <v-card>
        <v-card-title>
          Nouvelle valeur
        </v-card-title>
        <v-card-text>
          <v-text-field
            v-model="dialogValue"
            type="text"
            placeholder="Nouvelle valeur..."
            label="valeur"
          />
        </v-card-text>
        <v-card-actions class="d-flex justify-end">
          <v-btn color="error" @click="cancelModifyValue">Annuler </v-btn>
          <v-btn color="success" @click="modifyValue">Valider </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expand-transition>
      <v-card elevation="0" class="rounded-xl">
        <v-card-text v-show="!contentHidden" class="pa-0">
          <v-data-table
            class="rounded-xl"
            :hide-default-footer="!paginate"
            :headers="myHeaders"
            :disable-sort="disableSort"
            :items="table.rows"
            :server-items-length="itemsCount"
            :search="dataset ? '' : recherche"
            :loading="loading"
            :options.sync="options"
            :footer-props="{
              itemsPerPageOptions: [10, 50, 100]
            }"
            @click:row="clickRowEmitEvent($event)"
          >
            <template v-slot:item="{ item, headers }">
              <tr @click="clickRowEmitEvent(item)">
                <template v-for="(c, i) in item">
                  <td
                    v-if="!ignoredColumns.includes(i)"
                    :key="i"
                    :style="evaluateStyle(item, headers, i)"
                    class="text-left"
                  >
                    <template
                      v-if="modification && !blockedColumns.includes(i)"
                    >
                      <v-text-field
                        v-model="item[i]"
                        outlined
                        dense
                        class="mt-5"
                        @click="openPopinModifyValue(item['primary_key'], i, c)"
                      >
                        {{ item[i] }}
                      </v-text-field>
                    </template>
                    <template v-else-if="isNaN(c)">
                      {{ c }}
                    </template>
                    <template v-else-if="formatType == 'percentage'">
                      {{ c || 0 }} %
                    </template>
                    <template v-else-if="formatType == 'currency'">
                      {{ c | currency }}
                      €
                    </template>
                    <template v-else>
                      {{ c }}
                    </template>
                  </td>
                </template>
                <template v-if="editFunction">
                  <td>
                    <v-btn
                      :disabled="isEditDisabled(item)"
                      icon
                      class="primary--text"
                      @click="editFunction(item, $event)"
                    >
                      <v-icon>mdi-pencil</v-icon>
                    </v-btn>
                  </td>
                </template>
              </tr>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-expand-transition>
  </v-card>
</template>

<script>
import Numeral from "@/plugins/numeral";
import QueryService from "@/services/QueryService.js";
import DatasetService from "@/services/DatasetService.js";
import _ from "lodash";
import axios from "axios";

export default {
  filters: {
    currency: function(value) {
      return Numeral(value).format();
    }
  },
  props: {
    ignoredColumns: {
      type: Array,
      default: () => []
    },
    paginate: {
      type: Boolean,
      default: false
    },
    disableSort: {
      type: Boolean,
      default: false
    },
    card: {
      type: Boolean,
      default: false
    },
    conditionalFormat: {
      type: Array,
      default: () => []
    },
    formatType: {
      type: String,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    queryName: {
      type: String,
      default: null
    },
    recherche: {
      type: [String, Object],
      default: ""
    },
    iseditable: {
      type: Boolean,
      default: false
    },
    editFunction: {
      type: Function,
      default: null
    },
    isEditDisabled: {
      type: Function,
      default: () => false
    },
    unsavedQueryData: {
      type: Object,
      default: () => {
        return {
          rows: [],
          columns: []
        };
      }
    },
    dataset: {
      type: String,
      default: null
    },
    datasetFilter: {
      type: Object,
      default: null
    },
    blockedColumns: {
      type: Array,
      default: () => []
    },
    refreshKey: {
      type: Boolean,
      default: false
    },
    searchColumn: {
      type: [String, Array],
      default: null
    },
    searchThroughGenericFilter: {
      type: Boolean,
      default: false
    },
    transformValueColumn: {
      type: [String, Array],
      default: null
    },
    transformValueMapping: {
      type: Object,
      default: null
    },
    defaultSort: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
      contentHidden: false,
      table: {
        rows: [],
        columns: []
      },
      modification: false,
      oldTableData: {},
      dialogValue: "",
      valueToModify: {
        id: null,
        column: ""
      },
      openDialog: false,
      isDifferent: false,
      debounceTimer: null,
      loading: false,
      itemsCount: 0,
      options: { page: 1, itemsPerPage: 50 },
      request: null
    };
  },
  computed: {
    different() {
      const buff = [];
      for (let i = 0; i < this.table.rows.length; i++) {
        for (let j = 0; j < Object.keys(this.table.rows[i]).length; j++) {
          const key = Object.keys(this.table.rows[i])[j];
          if (this.table.rows[i][key] !== this.oldTableData.rows[i][key]) {
            buff.push(this.table.rows[i]);
          }
        }
      }
      return buff;
    },
    myHeaders() {
      return this.table.columns.concat(
        this.editFunction ? [{ text: "Actions", value: "actions" }] : []
      );
    }
  },
  watch: {
    queryName() {
      this.get();
    },
    refreshKey() {
      this.get();
    },
    unsavedQueryData() {
      if ("columns" in this.unsavedQueryData) {
        if (this.unsavedQueryData.columns.length > 0) {
          this.render(this.unsavedQueryData);
        }
      }
    },
    table: {
      deep: true,
      handler: function(newVal) {
        this.isDifferent = !_.isEqual(this.table.rows, this.oldTableData.rows);
        this.$emit("modification", this.isDifferent);
      }
    },
    recherche() {
      this.search();
    },
    options: {
      handler() {
        if (this.recherche !== "") {
          this.search();
        } else {
          this.get();
        }
      },
      deep: true
    }
  },
  methods: {
    cancel() {
      if (this.request) {
        this.request.cancel();
      }
    },
    handleCancelToken() {
      this.cancel();
      const axiosSource = axios.CancelToken.source();
      this.request = { cancel: axiosSource.cancel };
      return axiosSource;
    },
    evaluateStyle(item, headers, index) {
      const $ = this;
      const conditionalFormat = $.lo.find(headers, v => v.value === index)
        .conditionalFormat;
      if (conditionalFormat.condition(item[index])) {
        return conditionalFormat.style;
      } else {
        return {};
      }
    },
    mapValues(rows) {
      rows.forEach(row => {
        Object.keys(row).forEach(key => {
          if (
            this.transformValueColumn &&
            this.transformValueColumn.includes(key)
          ) {
            const valueMapped = this.transformValueMapping[row[key]];
            if (valueMapped) {
              row[key] = valueMapped;
            }
          }
        });
      });
      return rows;
    },
    get() {
      this.loading = true;
      const $ = this;
      const axiosSource = this.handleCancelToken();
      if ($.queryName) {
        QueryService.run($, $.queryName, [], axiosSource.token).then(r => {
          this.render(r.data);
        });
      } else if (this.dataset != null) {
        const { page, itemsPerPage } = this.options;
        let params = this.datasetFilter;

        if ($.defaultSort) {
          params = { ...params, sort: $.defaultSort };
        }

        DatasetService.get(
          this,
          this.dataset,
          params,
          "",
          page,
          itemsPerPage,
          axiosSource.token
        ).then(result => {
          this.itemsCount = result.data.count;
          result.data = result.data.results;
          const data = {
            columns: [],
            rows: []
          };
          if (result.data.length > 0) {
            // Set des columns
            for (let i = 0; i < Object.keys(result.data[0]).length; i++) {
              const key = Object.keys(result.data[0])[i];
              data.columns.push({ name: key });
            }

            data.rows = result.data;
            data.rows = this.mapValues(data.rows);
          }

          this.render(data);
        });
      }
    },
    search() {
      if (this.dataset != null) {
        const headers = "";

        if (this.recherche === "") {
          this.get();
        } else {
          clearTimeout(this.debounceTimer);
          this.debounceTimer = setTimeout(() => {
            const { page, itemsPerPage } = this.options;
            this.loading = true;
            const axiosSource = this.handleCancelToken();
            if (this.searchThroughGenericFilter) {
              const searchValues = {};

              for (const i in this.recherche) {
                searchValues[i] = this.recherche[i];
              }

              DatasetService.filter(
                this,
                this.dataset,
                searchValues,
                page,
                itemsPerPage,
                axiosSource.token
              ).then(result => {
                this.itemsCount = result.data.count;
                const data = {
                  columns: [],
                  rows: []
                };
                if (result.data.results.length > 0) {
                  // Set des columns
                  for (
                    let i = 0;
                    i < Object.keys(result.data.results[0]).length;
                    i++
                  ) {
                    const key = Object.keys(result.data.results[0])[i];
                    data.columns.push({ name: key });
                  }

                  data.rows = result.data.results;
                }

                data.rows = this.mapValues(data.rows);

                this.render(data);
              });
            } else {
              DatasetService.search(
                this,
                this.dataset,
                headers,
                this.searchColumn,
                this.recherche,
                page,
                itemsPerPage,
                axiosSource.token
              ).then(result => {
                this.itemsCount = result.data.count;
                const data = {
                  columns: [],
                  rows: []
                };
                if (result.data.results.length > 0) {
                  // Set des columns
                  for (
                    let i = 0;
                    i < Object.keys(result.data.results[0]).length;
                    i++
                  ) {
                    const key = Object.keys(result.data.results[0])[i];
                    data.columns.push({ name: key });
                  }

                  data.rows = result.data.results;
                }

                data.rows = this.mapValues(data.rows);

                this.render(data);
              });
            }
          }, 500);
        }
      }
    },
    render(data) {
      const $ = this;
      data.columns = data.columns.map(c => {
        const condFormats = $.lo.filter($.conditionalFormat, f =>
          f.cols.includes(c.name)
        );
        return {
          text:
            condFormats.length > 0
              ? condFormats[0].text
              : $.lo.lowerCase(c.name),
          value: c.name,
          class: $.ignoredColumns.includes(c.name) ? "d-none" : "",
          align: "left",
          sort: false,
          type: c.type,
          conditionalFormat:
            condFormats.length > 0
              ? condFormats[0]
              : {
                  condition: v => false,
                  style: {}
                }
        };
      });
      $.table = data;
      $.oldTableData = _.cloneDeep($.table);
      this.loading = false;
    },
    enableModification() {
      const $ = this;
      if (this.modification === false) {
        this.$store.commit("confirm", {
          title: "Activation des modifications",
          message:
            "Vous êtes entrain d'activer les modifications, toutes modifications modifiera les données. Êtes vous sur de vouloir continuer ?",
          color: "warning",
          onCancel: () => {},
          onConfirm: () => {
            // On active le mode modification
            // This tricks is to create a copy of this.table's data (and not copy the pointer)
            $.oldTableData = _.cloneDeep($.table);
            $.modification = !$.modification;
          }
        });
      } else {
        // On sauvegarde
        if (this.different.length > 0) {
          this.$store.commit("confirm", {
            title: "Modification en cours",
            message:
              "Vous avez modifié des données, êtes vous sur de vouloir les enregistrer ?",
            color: "warning",
            onCancel: () => {
              this.reset();
            },
            onConfirm: () => {
              // Update du backend
              this.save();
            }
          });
        } else {
          this.modification = false;
        }
      }
    },
    openPopinModifyValue(pk, column, c) {
      this.openDialog = true;
      this.valueToModify.id = pk;
      this.valueToModify.column = column;
      this.dialogValue = c;
    },
    modifyValue() {
      this.table.rows.filter(
        row => row.primary_key === this.valueToModify.id
      )[0][this.valueToModify.column] = this.dialogValue;
      this.openDialog = false;
      this.dialogValue = "";
      this.valueToModify.id = null;
      this.valueToModify.column = "";
    },
    cancelModifyValue() {
      this.openDialog = false;
      this.valueToModify.id = null;
      this.valueToModify.column = "";
      this.dialogValue = "";
    },
    async save() {
      const $ = this;
      const fields = [];
      for (let i = 0; i < $.table.columns.length; i++) {
        fields.push($.table.columns[i].text);
      }

      const promises = [];
      for (let i = 0; i < this.different.length; i++) {
        promises.push(
          DatasetService.update($, $.dataset, this.different[i], fields, false)
        );
      }
      await Promise.all(promises);
      $.$store.commit("success", "La mise à jour des données a été effectué");
      this.modification = false;
    },
    reset() {
      this.modification = false;
      this.table = _.cloneDeep(this.oldTableData);
    },
    clickRowEmitEvent(event) {
      this.$emit("rowevent", event);
    }
  }
};
</script>
