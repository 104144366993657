<template>
  <v-container fluid class="fill-height pr-0">
    <v-col cols="12" class="align-self-start">
      <v-tabs v-model="tab">
        <v-tab>Users</v-tab>
        <v-tab>Groups</v-tab>
      </v-tabs>
      <v-tabs-items v-model="tab" width="100%">
        <v-tab-item>
          <v-row>
            <v-col class="fill-height pr-0">
              <manage-users
                fill-height
                searchable
                :authorized-groups="authorizedGroups"
              />
            </v-col>
          </v-row>
        </v-tab-item>
        <v-tab-item>
          <v-row>
            <v-col class="fill-height pr-0">
              <group-company-settings-table
                :authorized-groups="authorizedGroups"
              />
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-col>
  </v-container>
</template>
<script>
import UsersCrud from "@/components/hmd/Users/UsersCrud";
import GroupCompanySettingsTable from "@/components/hmd/GroupCompanySettingsTable";
import Constant from "@/constant";

export default {
  name: "Data",
  components: {
    GroupCompanySettingsTable,
    "manage-users": UsersCrud
  },
  data: function() {
    return {
      tab: 0
    };
  },
  computed: {
    authorizedGroups() {
      return Constant.fetch_users_from_group(this.$store.getters.group);
    }
  }
};
</script>

<style scoped></style>
