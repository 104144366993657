import { HTTP } from "@/utils/http-common";
import Constant from "@/constant";
import axios from "axios";

export default {
  get: function($, query) {
    return new Promise((resolve, reject) => {
      HTTP.get(`query/manage/${query}/`)
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err);
        });
    });
  },
  run: function($, query, parameters = [], cancelToken = null) {
    return new Promise((resolve, reject) => {
      HTTP.get(`query/manage/${query}/results/`, {
        params: {
          parameters: parameters
        },
        cancelToken: cancelToken
      })
        .then(response => resolve(response.data))
        .catch(err => {
          if (axios.isCancel(err)) {
            resolve({ data: { results: [], count: 0 } });
          }
          return reject(err);
        });
    });
  },
  runKPI: function($, query, parameters = []) {
    return new Promise((resolve, reject) => {
      HTTP.get(`query/manage/kpi/${query}/render/`, {
        params: {
          parameters: parameters
        }
      })
        .then(response => resolve(response.data))
        .catch(err => {
          return reject(err);
        });
    });
  },
  serialNumber: function(
    $,
    parameters = [],
    csvFile = false,
    page = -1,
    pageSize = -1,
    cancelToken = null
  ) {
    const params = {
      parameters: parameters,
      file_format: "",
      page: page < 1 ? undefined : page,
      page_size: pageSize < 1 ? undefined : pageSize
    };
    if (csvFile) {
      params.file_format = "csv";
    }
    return new Promise((resolve, reject) => {
      HTTP.post("bytel/serialnumber/", {
        params,
        cancelToken: cancelToken
      })
        .then(r => {
          if (!csvFile) {
            resolve(r.data);
            return;
          }
          const uri = encodeURI("data:text/csv;charset=utf-8," + r.data);
          const link = document.createElement("a");
          link.setAttribute("href", uri);
          link.setAttribute(
            "download",
            "SUIVI_DOSSIER_" + new Date().toISOString().slice(0, 19) + ".csv"
          );
          link.click();
          resolve(link);
        })
        .catch(err => {
          let errorMessage = "Erreur sur la recherche.";
          if (err.response?.data?.error) {
            errorMessage = err.response.data.error
          }
          $.$store.commit("error", {
            msg: errorMessage,
            color: "error"
          });
          return reject(err);
        });
    });
  },
  runAnon($ = null, query = "", parameters = []) {
    return new Promise((resolve, reject) => {
      HTTP.post("query/results/", {
        project_id: Constant.project,
        query: query,
        parameters: parameters
      })
        .then(response => resolve(response.data))
        .catch(err => reject(err));
    });
  },
  sendEmail: function($, bodyVariables, subject, template) {
    return new Promise((resolve, reject) => {
      HTTP.post("bytel/sendmail/", {
        body_variables: bodyVariables,
        subject: subject,
        template: template
      })
        .then(response => {
          $.$store.commit("success", "Email envoyé");
          resolve(response.data);
        })
        .catch(err => {
          $.$store.commit("error", {
            msg: "L'email n'a pas pu être envoyé.",
            color: "error"
          });
          return reject(err);
        });
    });
  }
};
